/* import __COLOCATED_TEMPLATE__ from './dropdown-language.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { alias } from 'macro-decorators';

import LOCALES_ARRAY from 'qonto/constants/locales';

/**
 * !!! Important !!!
 * This component was cope-paste from qonto-register
 * Source code can be found here:
 * component - https://gitlab.qonto.co/front/qonto-register-js/-/blob/master/app/components/dropdown-language.js
 * template - https://gitlab.qonto.co/front/qonto-register-js/-/blob/master/app/components/dropdown-language.hbs
 * styles - https://gitlab.qonto.co/front/qonto-register-js/-/blob/master/app/components/dropdown-language.module.css
 */
export default class DropdownLanguage extends Component {
  @service localeManager;
  @service userManager;
  @service segment;

  locales = LOCALES_ARRAY;

  @alias('localeManager.locale') locale;

  get selectedLanguage() {
    return this.locales.find(({ code }) => code === this.localeManager.locale);
  }

  handleUpdateLanguageTask = dropTask(async language => {
    let prevLocale = this.locale;
    await this.localeManager.setLocale(language.code, { canWriteCookie: true });

    let user = this.userManager.currentUser;

    try {
      // if we are not logged in we still want to be able to update the language
      if (user) {
        user.set('locale', language.code);
        if (!user.isNew) {
          await user.save();
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}

    this.segment.identify({ browser_locale: this.locale });
    this.segment.track('onboarding_dropdown_language_updated', {
      locale_from: prevLocale,
      locale_to: language.code,
      page_url: window.location.pathname,
    });
  });
}
