export default {
  "row": "pE",
  "icon": "pd",
  "cell": "pI",
  "empty": "pt",
  "no-padding": "pM",
  "cell-content": "pP",
  "right": "pl",
  "cube": "pX"
};
