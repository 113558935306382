export default {
  "cell": "dg caption-bold",
  "empty": "dD",
  "transaction": "dV",
  "member": "dL",
  "method": "dT",
  "operation-date": "dz",
  "amount": "da",
  "label": "dH caption-bold",
  "active": "dO"
};
