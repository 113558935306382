export default {
  "wrapper": "zj",
  "label": "zW",
  "input": "zu",
  "icon-trash": "aS",
  "hidden": "ac",
  "icon-color": "aq",
  "icon-edit": "aZ",
  "icon-edit-save": "aR",
  "button-container": "aQ"
};
