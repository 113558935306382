export default {
  "row": "pC body-1",
  "active": "pk",
  "cell": "pJ body-2",
  "empty": "ps",
  "no-padding": "pF",
  "cell-content": "pg",
  "item-disabled": "pD",
  "item-amount": "pV body-1",
  "centered-cell": "pL",
  "debitor": "pT",
  "item-counterparty-icon": "pz mr-16",
  "avatar": "pa",
  "item-counterparty-name": "pH",
  "item-counterparty-reference": "pO",
  "item-counterparty-name-status": "pm",
  "item-counterparty-name-text": "pp",
  "amount-on-hold": "px",
  "icon-on-hold": "pw"
};
