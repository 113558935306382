/* import __COLOCATED_TEMPLATE__ from './bic-swift.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class BeneficiaryTypeBicSwiftComponent extends Component {
  @service intl;

  get accountNumberErrorMessage() {
    let { enableValidations, validations } = this.args;

    if (enableValidations) {
      let message = validations.attrs.accountNumber?.message;
      if (message === 'invalid_account_number') {
        return this.intl.t('validations.errors.invalid-account-number');
      }
      return message;
    }

    return null;
  }

  get bicErrorMessage() {
    let { enableValidations, validations } = this.args;

    if (this.enableBicValidation || enableValidations) {
      let message = validations.attrs.bic?.message;
      if (message === 'invalid_bic') {
        return this.intl.t('validations.errors.invalid-bic-swift');
      }
      if (message === 'unsupported_identifier_country') {
        return this.intl.t('transfers.errors.unsupported-bic-country');
      }

      return message;
    }

    return null;
  }

  get enableBicValidation() {
    return Boolean(this.args.bic?.length) && this.validateBicTask.performCount;
  }

  validateBicTask = dropTask(async () => {
    if (this.args.validateTask) await this.args.validateTask.perform('bic');
  });
}
