/* import __COLOCATED_TEMPLATE__ from './logo.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { canAnimate } from 'qonto/utils/chart';

export default class DualNavLogoComponent extends Component {
  @service sentry;
  isChrome = /Chrome/.test(navigator.userAgent);

  get shouldShowStaticLogo() {
    return this.args.state === 'responsive' || !canAnimate();
  }

  @action
  handleError(error) {
    // Here the error is a LottieError so we cannot use shouldSendToSentry
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}
