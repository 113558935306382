export default {
  "envelope": "ax",
  "email": "aw",
  "logo": "aN",
  "subject": "av",
  "message": "ao",
  "payment-details": "an",
  "contact": "ai",
  "detail": "aA",
  "cta": "aY"
};
