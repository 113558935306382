/* import __COLOCATED_TEMPLATE__ from './bookkeeping.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

import transactionHasVat from 'qonto/utils/transaction-has-vat';

export default class AttachmentsSidebarBookkeepingComponent extends Component {
  @not('args.transaction.declined') hasAccountingValue;

  @service segment;
  @service abilities;

  get isActivityTagSelectDisabled() {
    let { isTax, isPayLater, credit } = this.args.transaction;
    return isTax || (isPayLater && credit);
  }

  get canDisplayVAT() {
    return this.abilities.can('view vat bookkeeping') && transactionHasVat(this.args.transaction);
  }

  get isVatDisabled() {
    let { isFee } = this.args.transaction;
    return isFee;
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }

  @action
  handleActivityTagSelect(activityTagCode) {
    let source = 'bookkeeping_details';
    this.segment.track('transaction_category_selected', {
      source,
    });
    this.args.selectTag(this.args.transaction, activityTagCode, source);
  }
}
