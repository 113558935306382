export default {
  "aside": "Zr",
  "aside-section": "Zb",
  "aside-content": "Zy",
  "aside-footer": "ZU",
  "aside-title": "Zj caption-bold",
  "accountant-upsell": "ZW",
  "file-status": "Zu",
  "file-status-icon": "RS",
  "file-status-text": "Rc",
  "file-status-label": "Rq body-1",
  "file-status-description": "RZ body-2"
};
