/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class CardSidebarRestrictionsComponent extends Component {
  @service abilities;

  get showActiveDaysOption() {
    let activeDaysOptions = this.args.card?.activeDaysOption;
    let canViewResrictions = this.abilities.can('update settings card');

    return activeDaysOptions || canViewResrictions;
  }

  get showCategoryTagsOption() {
    if (this.abilities.cannot('view restrictive categories card')) {
      return false;
    }

    let hasCategoryTags = this.args.card?.hasCategoryTags;
    let canViewResrictions = this.abilities.can('update settings card');

    return hasCategoryTags || canViewResrictions;
  }
}
