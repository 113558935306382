export default {
  "rule-card": "LZ",
  "rule-content": "LR",
  "rule-options": "LQ",
  "visible": "Le",
  "dropdown-btn": "LB",
  "delete-btn": "LE",
  "btn--tertiary": "Ld",
  "delete-rule-btn": "LI",
  "transaction-estimate": "Lt"
};
