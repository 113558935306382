export default {
  "budget-edit-form": "IB",
  "period-label": "IE",
  "periods": "Id",
  "yearly": "II",
  "tooltips": "It",
  "year": "IM",
  "total-budget": "IP",
  "total-budget-value": "Il",
  "divider": "IX",
  "buttons": "IC"
};
