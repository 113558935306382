/* import __COLOCATED_TEMPLATE__ from './multi-accounts.hbs'; */
import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const CARD_IMAGES = {
  de: '/illustrations/upsell/de/trigger.png',
  en: '/illustrations/upsell/en/trigger.png',
  es: '/illustrations/upsell/es/trigger.png',
  fr: '/illustrations/upsell/fr/trigger.png',
  it: '/illustrations/upsell/it/trigger.png',
};

export default class DiscoverUpsellMultiAccountsComponent extends Component {
  badgeHighlight = BadgeHighlight;

  @service localeManager;
  @service organizationManager;
  @service subscriptionManager;
  @service modals;
  @service router;
  @service segment;
  @service sentry;

  constructor() {
    super(...arguments);
    if (
      variation('feature--boolean-optimized-upsell') &&
      !this.trialInfo &&
      !this.hasInitialTrial
    ) {
      this.initRecommendationTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          if (ErrorInfo.for(error).shouldSendToSentry && !(error instanceof NotFoundError)) {
            this.sentry.captureException(error);
          }
        });
    }
  }

  get imagePath() {
    return CARD_IMAGES[this.localeManager.locale];
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('multi_accounts');
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription.hasInitialTrial;
  }

  @action onTrialClick() {
    let { role } = this.organizationManager.membership;
    let origin = this.router.currentRouteName.includes('accounts') && 'accounts_page';

    this.segment.track('upsell_multi-account_clicked', {
      role,
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      price_plan: this.subscriptionManager.currentPricePlan.code,
      origin,
    });

    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  @action onClickUpsell() {
    let { role } = this.organizationManager.membership;
    let { end_date } = this.subscriptionManager.currentSubscription.activeTrial;

    this.segment.track('upsell_multi-account_clicked', {
      role,
      price_plan: this.subscriptionManager.currentPricePlan.code,
      trial_state: TRACKING_TRIAL_STATE.INITIAL_TRIAL,
      end_date,
    });
  }

  onClick = () => {
    this.args.onClick(
      variation('feature--boolean-optimized-upsell')
        ? this.initRecommendationTask.last?.value
        : null
    );
  };

  initRecommendationTask = dropTask(async () => {
    return await this.subscriptionManager.upgradeRecommendation('multiAccounts');
  });
}
