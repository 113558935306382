import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './info.strict-module.css';
export default class ApprovalWorkflowConditionInfo extends Component {
    @service
    intl;
    formattedValue(value1, isAmountB1) {
        let valueAsNumber1 = parseFloat(value1, 10);
        if (isAmountB1) valueAsNumber1 = valueAsNumber1 - 0.01;
        return this.intl.formatNumber(valueAsNumber1, {
            currency: 'EUR',
            style: 'currency',
            maximumFractionDigits: 2
        });
    }
    get captionMessage() {
        let { conditionAmount: conditionAmount1, nextRulesetConditionAmount: nextRulesetConditionAmount1 } = this.args;
        if (!conditionAmount1) return;
        if (nextRulesetConditionAmount1) {
            let amountA1 = this.formattedValue(conditionAmount1, false);
            let amountB1 = this.formattedValue(nextRulesetConditionAmount1, true);
            return this.intl.t('approval-workflows.form.conditions.message', {
                amountA: amountA1,
                amountB: amountB1
            });
        }
    }
    static{
        template(`
    {{#if @isVisible}}
      <p
        class='caption {{styles.info}}'
        data-test-workflow-form-condition-amount-caption-step
        ...attributes
      >{{this.captionMessage}}</p>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
