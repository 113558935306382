export default {
  "content": "ez",
  "balance": "ea",
  "balance-failed": "eH",
  "bank-infos": "eO",
  "bank-name": "em",
  "bank-identifier": "ep",
  "bank-iban": "ex",
  "footer-container": "ew",
  "links": "eN",
  "links--start-verification": "ev",
  "link": "eo",
  "start-verification-link": "en",
  "last-sync": "ei"
};
