/* import __COLOCATED_TEMPLATE__ from './payment-limits.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SHOW_BY_COOKIE_RULES } from 'qonto/components/show-by-cookie';
import { CARD_LEVELS_TRACKING, UPSELL_BANNERS_COOKIE_IDS } from 'qonto/constants/cards';
import { ORIGIN } from 'qonto/routes/flows/setup/cards/upsell';
import {
  getAvailableThisMonth,
  getAvailableToday,
  getPaymentMonthlyLimitsMaximum,
  isMonthlyLimitClose,
} from 'qonto/utils/cards/card-limits';

export default class CardSidebarPaymentLimits extends Component {
  @service abilities;
  @service intl;
  @service flowLinkManager;
  @service segment;

  upsellBannerCookieId = UPSELL_BANNERS_COOKIE_IDS.BANNER_PAYMENT;
  upsellBannerCookieRule = SHOW_BY_COOKIE_RULES.FIRST_OF_NEXT_MONTH;

  get availableToday() {
    let { card } = this.args;
    return getAvailableToday(
      card.paymentDailyLimit,
      card.paymentDailySpent,
      this.availableThisMonth
    );
  }

  get availableThisMonth() {
    let { card } = this.args;
    return getAvailableThisMonth(card.paymentMonthlyLimit, card.paymentMonthlySpent);
  }

  get hasAdvertisingCardDisclaimer() {
    let { card } = this.args;
    if (!card.isAdvertising) return false;

    return this.abilities.can('view payment limits disclaimer card', card);
  }

  get hasMetalCardDisclaimer() {
    let { card } = this.args;
    if (!card.isMetal || this.args.isScaEnabled) return false;

    return this.abilities.can('view payment limits disclaimer card', card);
  }

  get hasUpsellBanner() {
    let { card, cardsLimits } = this.args;
    if (!cardsLimits) return false;

    return (
      this.abilities.can('upsell card', card) &&
      isMonthlyLimitClose(
        getPaymentMonthlyLimitsMaximum(card.cardLevel, cardsLimits),
        card.paymentMonthlySpent
      )
    );
  }

  get disclaimerText() {
    return this.args.card.isOperational
      ? this.intl.t('cards.sidebar.disclaimer.payment-limits.extended.ready')
      : this.intl.t('cards.sidebar.disclaimer.payment-limits.extended.not-ready');
  }

  @action
  goToUpsellFlow() {
    this.segment.track('cards_upsell_opened', {
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'payment_limit',
    });

    this.flowLinkManager.transitionTo({
      name: 'card-upsell',
      stepId: 'upsell',
      queryParams: { cardId: this.args.card.id, origin: ORIGIN.PAYMENT_LIMITS },
    });
  }

  @action
  onUpsellBannerClose() {
    this.segment.track('cards_upsell_trigger_closed', {
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'payment_limit',
    });
  }
}
