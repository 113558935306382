export default {
  "wrapper": "Qu",
  "title": "eS",
  "button-icon": "ec",
  "download-button": "eq",
  "specification-list": "eZ",
  "certified-details-wrapper": "eR",
  "details-wrapper": "eQ",
  "certified-specification-list": "ee",
  "specification": "eB",
  "icon": "eE",
  "cross": "ed",
  "checkmark": "eI",
  "pit-link": "et"
};
