export default {
  "card-sidebar-header": "Mb",
  "content": "My",
  "card-image-container": "MU",
  "card-image": "Mj",
  "legacy": "MW",
  "type": "Mu",
  "type-container": "PS",
  "type-close": "Pc",
  "type-status-awaiting": "Pq",
  "type-status-live": "PZ",
  "type-status-paused": "PR",
  "type-status-ghost": "PQ",
  "actions-panel": "Pe",
  "actions-panel-header": "PB",
  "action-btn": "PE",
  "disclaimer": "Pd",
  "infobox": "PI",
  "btn-panel": "Pt",
  "required-action-btn": "PM",
  "kyc-disclaimer": "PP"
};
