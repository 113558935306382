export default {
  "connection-sidebar-header": "LH",
  "title": "LO",
  "close": "Lm",
  "api-client": "Lp",
  "api-client-avatar": "Lx",
  "api-client-name": "Lw",
  "disclaimer": "LN",
  "expired": "Lv"
};
