export default {
  "envelope": "HG",
  "payment-page": "Hr",
  "header": "Hb",
  "title": "Hy",
  "logo": "HU",
  "detail": "Hj",
  "dummy-field": "HW",
  "input": "Hu",
  "input--select": "OS",
  "input--readonly": "Oc",
  "placeholder": "Oq",
  "columns": "OZ",
  "mandate": "OR",
  "mandate-title": "OQ",
  "icon-chevron": "Oe",
  "mandate-details": "OB",
  "checkbox": "OE",
  "secure-note": "Od",
  "icon-lock": "OI",
  "cta": "Ot"
};
