export default {
  "header-cell": "mW",
  "empty": "mu",
  "right": "pS",
  "debitor": "pc",
  "date": "pq",
  "status": "pZ",
  "amount": "pR",
  "header-amount": "pQ",
  "header-content": "pe caption-bold",
  "active": "pB"
};
