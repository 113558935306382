export default {
  "panel-container": "In",
  "selector-container": "Ii",
  "container-title": "IA",
  "panel-title": "IY",
  "panel-title-text": "Ih",
  "panel-icon": "If",
  "icon": "IK",
  "animate-in": "IG",
  "panel-content": "Ir",
  "error-message": "Ib"
};
