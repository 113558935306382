export default {
  "sidebar": "Tf",
  "scroll": "TK",
  "header": "TG",
  "close": "Tr",
  "vat": "Tb",
  "labels": "Ty",
  "actions": "TU",
  "buttons-container": "Tj"
};
