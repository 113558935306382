/* import __COLOCATED_TEMPLATE__ from './language-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SUPPORTED_LOCALES } from 'qonto/constants/locales';
import { sortByOrgLocale } from 'qonto/utils/sorters';

const mapLanguagesToObject = intl => code => ({
  code,
  label: intl.t(`bank_accounts.share.download.link-${code}`),
});

export default class LanguageSelectComponent extends Component {
  @service segment;
  @service intl;
  @tracked language = null;

  constructor() {
    super(...arguments);
    this.options = SUPPORTED_LOCALES.map(mapLanguagesToObject(this.intl)).sort((a, b) =>
      sortByOrgLocale(a, b, this.organizationLocale)
    );
  }

  get organizationLocale() {
    return this.args.bankAccount.organization.legalCountry.toLowerCase();
  }

  get selectedLanguage() {
    let languageCode = this.language || this.args.defaultLocale;
    return this.options.find(option => option.code === languageCode);
  }

  @action
  trackLocaleDownload(locale) {
    if (locale === this.organizationLocale) {
      this.segment.track('account_details_download_local');
    } else {
      this.segment.track(`account_details_download_${locale}`);
    }
  }

  @action
  handleUpdate(language) {
    this.language = language.code;
  }
}
