export default {
  "sidebar-header": "Oj",
  "sidebar-header__top": "OW",
  "sidebar-header__close-button": "Ou",
  "close-button": "mS",
  "sidebar-header__body": "mc",
  "sidebar-header__content": "mq",
  "sidebar-header__date": "mZ",
  "infobox": "mR",
  "infobox--link": "mQ",
  "ghost": "me",
  "fail": "mB",
  "success": "mE"
};
