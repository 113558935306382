/* import __COLOCATED_TEMPLATE__ from './beneficiary-type.hbs'; */
import Component from '@glimmer/component';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';

export default class BeneficiaryTypeComponent extends Component {
  get isAba() {
    return this.args.type === ACCOUNT_TYPES.ABA;
  }

  get isBicSwift() {
    return this.args.type === ACCOUNT_TYPES.BIC_SWIFT;
  }

  get isIban() {
    return this.args.type === ACCOUNT_TYPES.IBAN;
  }
}
