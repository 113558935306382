/* import __COLOCATED_TEMPLATE__ from './withdrawal-limits.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SHOW_BY_COOKIE_RULES } from 'qonto/components/show-by-cookie';
import { CARD_LEVELS_TRACKING, UPSELL_BANNERS_COOKIE_IDS } from 'qonto/constants/cards';
import { ORIGIN } from 'qonto/routes/flows/setup/cards/upsell';
import {
  getAtmMonthlyLimitsMaximum,
  getAvailableThisMonth,
  getAvailableToday,
  isMonthlyLimitClose,
} from 'qonto/utils/cards/card-limits';

export default class CardSidebarWithdrawalLimits extends Component {
  @service abilities;
  @service flowLinkManager;
  @service segment;

  upsellBannerCookieId = UPSELL_BANNERS_COOKIE_IDS.BANNER_WITHDRAWAL;
  upsellBannerCookieRule = SHOW_BY_COOKIE_RULES.FIRST_OF_NEXT_MONTH;

  get availableToday() {
    let { card } = this.args;
    return getAvailableToday(card.atmDailyLimit, card.atmDailySpent, this.availableThisMonth);
  }

  get availableThisMonth() {
    let { card } = this.args;
    return getAvailableThisMonth(card.atmMonthlyLimit, card.atmMonthlySpent);
  }

  get hasUpsellBanner() {
    let { card, cardsLimits } = this.args;
    if (!cardsLimits) return false;

    return (
      this.abilities.can('upsell card', card) &&
      isMonthlyLimitClose(
        getAtmMonthlyLimitsMaximum(card.cardLevel, cardsLimits),
        card.atmMonthlySpent
      )
    );
  }

  @action
  goToUpsellFlow() {
    this.segment.track('cards_upsell_opened', {
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'withdrawal_limit',
    });

    this.flowLinkManager.transitionTo({
      name: 'card-upsell',
      stepId: 'upsell',
      queryParams: { cardId: this.args.card.id, origin: ORIGIN.WITHDRAWAL_LIMITS },
    });
  }

  @action
  onUpsellBannerClose() {
    this.segment.track('cards_upsell_trigger_closed', {
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'withdrawal_limit',
    });
  }
}
