/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, waitForQueue } from 'ember-concurrency';

import { PERIOD_KEYS } from 'qonto/constants/bookkeeping';

export default class BookkeepingPeriodSelectorDropdownComponent extends Component {
  @service('segment') segment;

  @tracked customPeriodIsOpen = false;

  lastIndex = 0;

  @action
  onInitDropDown(dropDownState) {
    this.dropDownState = dropDownState;
    if (this.args.onInit) {
      this.args.onInit(...arguments);
    }
  }

  @action
  getIndex() {
    return this.lastIndex++;
  }

  @action
  onClose() {
    this.lastIndex = 0;
    this.customPeriodIsOpen = false;
  }

  onOpenTask = dropTask(async () => {
    await waitForQueue('afterRender');
    this.focusedOptionElement?.focus();
  });

  @action
  selectItem(index) {
    let selectedItem = this.args.options[index];
    if (this.isCustom(selectedItem.key)) {
      this.customPeriodIsOpen = true;
    } else {
      this.args.update(selectedItem);
      this.dropDownState.actions.close();
    }
  }

  @action
  updateCustomPeriod({ startDate, endDate }) {
    let item = Object.assign({}, this.customPeriodItem, { startDate, endDate });
    this.args.update(item);
    this.dropDownState.actions.close();
  }

  closeCustomPeriodTask = dropTask(async () => {
    this.lastIndex = 0;
    this.customPeriodIsOpen = false;
    await waitForQueue('afterRender');
    this.focusedOptionElement?.focus();
  });

  @action
  onTriggerKeyDown(dropdown, event) {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        this.dropDownState.actions.open();
        break;
    }
  }

  @action
  onOptionKeyDown(event) {
    let { focusedOptionElement } = this;
    let focusedIndex = Number(focusedOptionElement?.dataset.optionIndex);

    switch (event.key) {
      case 'ArrowDown':
        this.dropdownElement.querySelector(`[data-option-index="${focusedIndex + 1}"]`)?.focus();
        break;
      case 'ArrowUp':
        this.dropdownElement.querySelector(`[data-option-index="${focusedIndex - 1}"]`)?.focus();
        break;
      case 'Enter':
      case ' ':
        focusedOptionElement?.click();
        break;
      case 'Escape':
        this.dropDownState.actions.close();
        break;
    }
  }

  @action
  onOptionMouseOver(e) {
    e.currentTarget.focus();
  }

  get dropdownElement() {
    return document.getElementById(`ember-basic-dropdown-content-${this.dropDownState.uniqueId}`);
  }

  get focusedOptionElement() {
    return (
      this.dropdownElement?.querySelector('[role=option]:focus') ||
      this.dropdownElement?.querySelector('[role=option]:hover') ||
      this.dropdownElement?.querySelector('[role=option][aria-current=true]') ||
      this.dropdownElement?.querySelector('[role=option][data-option-index="0"]')
    );
  }

  get selectedIndex() {
    return this.args.options.findIndex(({ key }) => key === this.args.value?.key);
  }

  isCustom(key) {
    return key === PERIOD_KEYS.CUSTOM_PERIOD;
  }

  get customPeriodItem() {
    if (this.isCustom(this.args.value?.key)) {
      return this.args.value;
    } else {
      return this.args.options.find(({ key }) => this.isCustom(key));
    }
  }
}
