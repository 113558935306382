/* import __COLOCATED_TEMPLATE__ from './visualizer.hbs'; */
import Component from '@glimmer/component';

export default class ApprovalWorkflowRulesetVisualizer extends Component {
  get rulesetsWithStepsAggregatedByOrder() {
    let { rulesets } = this.args;

    let groupedApprovers = this.transformRulesets(rulesets);

    return groupedApprovers ?? [];
  }

  /**
   * Combines steps that have the same order into a single step with values aggregated in an array.
   *
   * @param {Array} steps - An array of step objects that need to be combined.
   * @returns {Array} An array of combined step objects grouped by their order.
   */
  combineStepsFromSameOrder = steps => {
    let orderMap = steps.reduce((accumulator, step) => {
      if (!accumulator[step.order]) {
        accumulator[step.order] = {
          field: step.field,
          operation: step.operation,
          value: [],
          order: step.order,
          id: step.id,
        };
      }
      accumulator[step.order].value.push(step.value);
      return accumulator;
    }, {});

    return Object.values(orderMap);
  };

  transformRulesets = rulesets => {
    return rulesets.map(ruleset => {
      return {
        conditions: ruleset.conditions,
        steps: this.combineStepsFromSameOrder(ruleset.steps),
      };
    });
  };
}
