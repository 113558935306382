export default {
  "shadow": "ej",
  "placeholder-line": "eW",
  "placeholder-square": "eu",
  "loading": "BS",
  "name": "Bc title-4",
  "iban": "Bq caption",
  "transfers-button": "BZ",
  "cards-button": "BR",
  "mandates-button": "BQ",
  "menu-item": "Be body-2",
  "close-account": "BB",
  "kyc-disclaimer": "BE"
};
