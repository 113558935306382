/* import __COLOCATED_TEMPLATE__ from './checkout-form.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { loadScript } from 'qonto/utils/load-script';

export default class CheckoutForm extends Component {
  spinner = Spinner;

  @service intl;
  @service theme;

  frames = null;
  @tracked cardNumberError = null;
  @tracked cvvError = null;
  @tracked expiryError = null;

  @tracked elementsLoaded = [];

  constructor() {
    super(...arguments);
    this.insertFramesTask.perform().catch(ignoreCancelation);
  }
  get texts() {
    let { flowType } = this.args;
    if (flowType === 'payment-activation') {
      return {
        cardNumberLabel: this.intl.t('payment-activation-top-up.payment.card-number.label'),
        expirationDateLabel: this.intl.t('payment-activation-top-up.payment.expiration.label'),
        cvvLabel: this.intl.t('payment-activation-top-up.payment.cvv.label'),
        CTAText: this.intl.t('btn.confirm'),
      };
    }
    return {
      cardNumberLabel: this.intl.t('on-boarding.top-up.checkout.card-number.label'),
      expirationDateLabel: this.intl.t('on-boarding.top-up.checkout.expiration-date.label'),
      cvvLabel: this.intl.t('on-boarding.top-up.checkout.cvv.label'),
      CTAText: this.intl.t('on-boarding.top-up.checkout.cta'),
    };
  }

  insertFramesTask = dropTask(async () => {
    if (macroCondition(!isTesting())) {
      await this.loadFramesTask.perform();
    }

    this._initFrames();
  });

  loadFramesTask = dropTask(async () => {
    if (!window.Frames) {
      await loadScript('https://cdn.checkout.com/js/framesv2.min.js');
    }
  });

  _initFrames() {
    this.frames = window.Frames;

    let { isCurrentThemeDark } = this.theme;

    this.frames.init({
      publicKey: ENV.checkout.publicKey,
      debug: true,
      localization: this.args.locale,
      style: {
        base: {
          backgroundColor: isCurrentThemeDark ? '#1d1d1b' : '#fff',
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu",
          color: isCurrentThemeDark ? '#ebebe8' : '#1d1d1b', // --primary-black
          fontSize: '14px', // --body-2-font-size
          fontWeight: 'normal', // --body-2-font-weight
          lineHeight: '24px', // --body-2-line-height
          letterSpacing: '0px',
        },
        placeholder: {
          base: {
            color: '#999896', // --gray500
            textOverflow: 'ellipsis !important',
            opacity: 1,
            letterSpacing: '0px',
          },
        },
      },
    });

    this.frames.addEventHandler(this.frames.Events.FRAME_VALIDATION_CHANGED, event => {
      let { element, isEmpty, isValid } = event;

      let error = isEmpty || !isValid ? this.intl.t('validations.errors.invalid') : null;

      if (element === 'card-number') {
        this.cardNumberError = error;
      }

      if (element === 'expiry-date') {
        this.expiryError = error;
      }

      if (element === 'cvv') {
        this.cvvError = error;
      }
    });

    this.frames.addEventHandler(this.frames.Events.FRAME_ACTIVATED, event => {
      if (!this.elementsLoaded.includes(event.element)) {
        this.elementsLoaded.push(event.element);
      }

      // The event fires once for each IFrame loaded
      if (
        this.elementsLoaded.includes('card-number') &&
        this.elementsLoaded.includes('expiry-date') &&
        this.elementsLoaded.includes('cvv')
      ) {
        this.args.onFramesReady?.(event);
      }
    });
  }

  submitCardTask = dropTask(async () => {
    if (this.frames.isCardValid()) {
      this.frames.enableSubmitForm();
      let cardTokenized = await this.frames.submitCard();
      return this.args.onSubmitTask.linked().perform(cardTokenized, 'checkout');
    }
  });
}
