export default {
  "details": "TW",
  "top-details": "Tu",
  "name": "zS",
  "account-infobox": "zc",
  "row": "zq",
  "icon": "zZ",
  "account-number": "zR",
  "currency": "zQ",
  "activity-tag": "ze",
  "activity-icon": "zB",
  "edit-btn": "zE",
  "action-buttons-container": "zd"
};
