export default {
  "row": "mz",
  "icon": "ma",
  "cell": "mH",
  "empty": "mO",
  "no-padding": "mm",
  "cell-content": "mp",
  "right": "mx",
  "cube": "mw",
  "row--sidebar-open": "mN",
  "status": "mv"
};
