export default {
  "container": "Pl",
  "is-sidebar-content": "PX",
  "no-sep": "PC",
  "details": "Pk",
  "detail": "PJ",
  "name": "Ps",
  "content": "PF",
  "content-text": "Pg",
  "break-word": "PD",
  "primary": "PV",
  "links-container": "PL",
  "infos-container": "PT",
  "infos-disclaimer": "Pz",
  "upsell-container": "Pa"
};
