/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

export default class BankAccountsCard extends Component {
  @service modals;
  @service segment;
  @service router;

  get iban() {
    let { shouldDisplayStartVerification, account } = this.args;

    if (shouldDisplayStartVerification) {
      return account.ibanObfuscated;
    }

    return account.iban;
  }

  showRenameAccountModalTask = task(async () => {
    this.showActionsMenu = false;

    let modal;
    try {
      modal = this.modals.open('bank-accounts/account-rename-modal', {
        account: this.args.account,
      });
      await modal;
    } finally {
      modal.close();
    }
  });

  showCloseAccountModalTask = task(async () => {
    this.showActionsMenu = false;

    let modal;
    try {
      modal = this.modals.open('bank-accounts/account-close-modal', {
        bankAccount: this.args.account,
      });
      let { slug, accountType } = this.args.account;
      this.segment.track('bank_account_close_clicked', {
        slug,
        account_type: accountType,
      });
      await modal;
    } finally {
      modal.close();
    }
  });
}
