/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

export default class CardSidebarFooterComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service modals;

  unlockTask = dropTask(async card => {
    try {
      await card.unlock();
      let message = this.intl.t('toasts.cards.unlock');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  @action handleLostOrStolen() {
    this.modals.open('card/modals/suspend', {
      card: this.args.card,
    });

    this.segment.track('card_lost_stolen_clicked');
  }
}
