export default {
  "modal": "Tg",
  "features": "TD",
  "close-button": "TV",
  "header": "TL title-2",
  "header-text": "TT body-2",
  "feature": "Tz",
  "subheader": "Ta title-4",
  "text": "TH body-2",
  "cta": "TO",
  "container-img": "Tm",
  "img": "Tp",
  "link": "Tx"
};
