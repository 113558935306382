export default {
  "card-container": "Db",
  "compact": "Dy",
  "card-header": "DU mb-16",
  "application-logo": "Dj",
  "application-name": "DW",
  "application-bic": "Du caption-2",
  "application-title": "VS title-4 mb-8",
  "application-categories": "Vc",
  "application-card-tag": "Vq tag",
  "short-description": "VZ body-2"
};
