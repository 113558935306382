export default {
  "header-cell": "Hd",
  "type": "HI",
  "request-date": "Ht",
  "amount": "HM",
  "quick-actions": "HP",
  "empty": "Hl",
  "header-content": "HX caption-bold",
  "active": "HC"
};
