/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

export default class CardSettingsValidityPeriodComponent extends Component {
  @service intl;

  changeset = new Changeset(this.args.card);

  get period() {
    let today = dayjs().endOf('day').toDate();
    let oneMonth = dayjs(today).add(1, 'month').toDate();
    let yesterday = dayjs().subtract(1, 'day').endOf('day').toDate();
    let sixMonths = dayjs(yesterday).add(6, 'month').toDate();
    let oneYear = dayjs(yesterday).add(1, 'year').toDate();

    let range = [today, oneMonth, sixMonths, oneYear].map(date => ({
      date,
      formatted: dateToken({
        date,
        locale: 'en',
      }),
    }));

    // Cannot be in map due to ember-intl-analyzer and unused translations rule
    range[0].name = this.intl.t('cards.settings.validity-period.chip1');
    range[1].name = this.intl.t('cards.settings.validity-period.chip2');
    range[2].name = this.intl.t('cards.settings.validity-period.chip3');
    range[3].name = this.intl.t('cards.settings.validity-period.chip4');

    return { min: today, max: oneYear, range };
  }

  get errorMessage() {
    let preExpiresAt = this.changeset.preExpiresAt;
    return !preExpiresAt || typeof preExpiresAt !== 'object'
      ? this.intl.t('validations.errors.presence')
      : null;
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    if (!this.args.disablePersistingState && !this.isCtaDisabled) {
      this.changeset.execute();
    }
  }

  submitTask = dropTask(async () => {
    if (this.errorMessage) return;

    this.changeset.execute();
    await this.args.onSubmit();
  });

  @action
  setProperty(prop, val) {
    let newDate = val && dayjs(val).endOf('day').toDate();
    this.changeset.set(prop, newDate);
    this.persistState();
  }
}
