export default {
  "tooltip": "wN",
  "wrapper": "wv",
  "collapsed": "wo",
  "label": "wn body-1",
  "selected": "wi",
  "icon-wrapper": "wA",
  "icon": "wY",
  "locked-icon": "wh",
  "notification-count": "wf",
  "fade-in": "wK",
  "responsive": "wG"
};
