export default {
  "row": "zm",
  "active": "zp",
  "cell": "zx",
  "empty-cell": "zw",
  "counterparty": "zN",
  "counterparty-info": "zv",
  "name": "zo body-1",
  "qonto": "zn",
  "currency": "zi",
  "account-number": "zA caption",
  "custom-labels": "zY",
  "label": "zh"
};
