import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Timeline from 'qonto/components/stepper/timeline';
import { STATUS } from 'qonto/constants/approval-workflow-state';
import Approvals from '../visualizer/approvals';
import Conditions from '../visualizer/conditions';
import Success from '../visualizer/success';
export default class ApprovalWorkflowStateVisualizer extends Component {
    @service
    organizationManager;
    get hasRequestBeenRejected() {
        let { steps: steps1 } = this.args.approvalWorkflowState;
        return steps1.some((step1)=>step1.status === STATUS.REJECTED);
    }
    get shouldDisplaySuccessStep() {
        return !this.hasRequestBeenRejected;
    }
    isApprovedStep({ status: status1 }) {
        return status1 === STATUS.APPROVED;
    }
    isCurrentStep({ status: status1 }) {
        return status1 === STATUS.CURRENT;
    }
    isPendingStep({ status: status1 }) {
        return status1 === STATUS.PENDING;
    }
    isRejectedStep({ status: status1 }) {
        return status1 === STATUS.REJECTED;
    }
    filterStepsAfterRejectionStep(steps1) {
        let rejectedStepIndex1 = steps1.findIndex((step1)=>step1.status === STATUS.REJECTED);
        // If no rejection step found or if the rejection is the last step, we don't need to filter the steps
        if (rejectedStepIndex1 === -1 || rejectedStepIndex1 === steps1.length - 1) {
            return steps1;
        }
        return steps1.slice(0, rejectedStepIndex1 + 1);
    }
    get steps() {
        let { steps: steps1 } = this.args.approvalWorkflowState;
        if (this.hasRequestBeenRejected) {
            return this.filterStepsAfterRejectionStep(steps1);
        }
        return steps1;
    }
    get lastStep() {
        return this.steps.at(-1);
    }
    iconType({ status: status1 }) {
        switch(status1){
            case STATUS.REJECTED:
                return 'reject';
            case STATUS.CURRENT:
                return 'current';
        }
    }
    static{
        template(`
    <Timeline ...attributes>
      <:steps as |Step|>
        <Step @lineStyle='solid' @iconType='arrow'>
          <Conditions @conditions={{@approvalWorkflowState.conditions}} />
        </Step>
        {{#each this.steps as |singleStep|}}
          <Step
            @lineStyle={{if (this.isApprovedStep singleStep) 'solid'}}
            @iconType={{this.iconType singleStep}}
            @shouldHideProgressLine={{this.isRejectedStep singleStep}}
          >
            <Approvals @step={{singleStep}} @isWorkflowStateStatus={{true}} />
          </Step>
        {{/each}}
        {{#if this.shouldDisplaySuccessStep}}
          <Step
            @iconType={{if (this.isApprovedStep this.lastStep) 'success'}}
            @shouldHideProgressLine={{true}}
          >
            <Success @requestType={{@requestType}} />
          </Step>
        {{/if}}
      </:steps>
    </Timeline>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
