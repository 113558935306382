/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import {
  BANK_CONNECTION_FAILURE_STATUSES,
  BANK_CONNECTION_STATUS,
  CONNECTION_EXPIRATION_STATUS,
} from 'qonto/constants/connect';
import { Avatar } from 'qonto/react/components/connections/avatar';

export default class ConnectionsTableRowComponent extends Component {
  @service intl;

  Avatar = Avatar;

  get statusLevel() {
    let { connectionStatus } = this.args;

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus):
        return 'failure';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
        return 'warning';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return 'error';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return 'info';
    }
  }

  get iconName() {
    let { connectionStatus } = this.args;

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus):
        return 'icon_error_filled';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return 'icon_warning_sign_filled';

      case connectionStatus === BANK_CONNECTION_STATUS.SYNCHRONIZED:
      case connectionStatus === BANK_CONNECTION_STATUS.SYNC_IN_PROGRESS:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return 'icon_checkmark_rounded_filled';
    }
  }

  get statusMessage() {
    let { connectionStatus, expiresAt, lastSuccessfulSyncAt } = this.args;

    let expirationDate = dateToken({
      date: expiresAt,
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus): {
        let relativeLastSyncTime = dayjs(lastSuccessfulSyncAt).fromNow();

        return this.intl.t('settings.connections.status.failed', {
          relativeTime: relativeLastSyncTime,
        });
      }

      case connectionStatus === BANK_CONNECTION_STATUS.SYNCHRONIZED:
      case connectionStatus === BANK_CONNECTION_STATUS.SYNC_IN_PROGRESS:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return this.intl.t('settings.connections.status.connected', { expirationDate });

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
        return this.intl.t('settings.connections.status.expires-soon', { expirationDate });

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return this.intl.t('settings.connections.status.expired', { expirationDate });

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.UNKNOWN:
        return '-';
    }
  }
}
