/* import __COLOCATED_TEMPLATE__ from './group.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, timeout } from 'ember-concurrency';

const ANIMATION_IN_DURATION_MS = 260;
const ANIMATION_OUT_DURATION_MS = 210;

export default class CollapseOptionsGroupComponent extends Component {
  @tracked selecting = false;
  @tracked selected = false;
  @tracked clearing = false;
  @tracked showing = false;

  get collapsed() {
    let { value, clearable } = this.args.selectedOption || {};

    if (this.args.hideCustomInputs) return false;

    return Boolean(clearable && value);
  }

  get wrap() {
    return this.args.allowWrapping && !this.collapsed;
  }

  selectOptionTask = dropTask(async option => {
    let { value, clearable } = option;

    if (value !== 0 && !this.args.hideCustomInputs) {
      this.selecting = clearable;
      this.selected = false;
    }

    this.args.update(option);

    if (value !== 0 && !this.args.hideCustomInputs) {
      await timeout(ANIMATION_IN_DURATION_MS);

      this.selecting = false;
      this.selected = true;
    }
  });

  clearOptionTask = dropTask(async option => {
    let { value } = option;
    this.selected = false;

    if (value !== 0) {
      this.clearing = true;
      await timeout(ANIMATION_OUT_DURATION_MS);
    }

    this.args.update({});

    if (value !== 0) {
      this.showing = true;
      this.clearing = false;

      await timeout(ANIMATION_OUT_DURATION_MS);
      this.showing = false;
    }

    // eslint-disable-next-line ember/no-array-prototype-extensions
    this.args.clear?.();
  });
}
