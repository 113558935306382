/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from 'design-system-kit';
import Tether from 'tether';

import { FEATURE_RELEASE_DATE } from 'qonto/constants/reminders-configuration';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class ClientsTableItemQuickActionsComponent extends Component {
  badge = Badge;

  @service flowLinkManager;
  @service segment;
  @service subscriptionManager;

  get isRemindersFeatureNew() {
    if (safeLocalStorage.getItem('automated-invoice-reminders-discovered') === 'true') {
      return false;
    }

    let currentDate = new Date();
    let expirationDate = new Date(FEATURE_RELEASE_DATE);
    expirationDate.setDate(expirationDate.getDate() + 30);

    return currentDate <= expirationDate;
  }

  get isSoloBasicPlan() {
    return this.subscriptionManager.currentPricePlan?.groupCode === 'solo_basic';
  }

  get isEligibleForFreeTrial() {
    return this.subscriptionManager.currentSubscription.availableTrials.length ? true : false;
  }

  @action
  toggleMenu(showMenu) {
    if (showMenu) {
      this.segment.track('client-list_quick-action-icon_clicked');
      scheduleOnce('afterRender', this, this.positionTether);
    }
  }

  positionTether() {
    if (typeof Tether !== 'undefined' && typeof Tether.position === 'function') {
      Tether.position();
    }
  }

  @action
  onSetupReminders() {
    safeLocalStorage.setItem('automated-invoice-reminders-discovered', 'true');

    if (this.isSoloBasicPlan) {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: {
          lineup: 'solo',
          recurrence: 'monthly',
        },
      });
    } else {
      this.segment.track('client-list_quick-action_clicked', { quick_action: 'set_up_reminder' });
      this.args.onSetReminders();
    }
  }

  @action
  onEditReminders() {
    this.segment.track('client-list_quick-action_clicked', { quick_action: 'edit_reminder' });
    this.args.onSetReminders();
  }
}
