/* import __COLOCATED_TEMPLATE__ from './account-statements-popup.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from 'design-system-kit';
import { task } from 'ember-concurrency';

export default class extends Component {
  spinner = Spinner;

  @service store;

  constructor() {
    super(...arguments);
    this.loadStatementsTask.perform().catch(() => {
      // errors are handled by flash messages
    });
  }

  @action
  close() {
    this.args.close();
    this.args.data.onClose?.();
  }

  loadStatementsTask = task(async () => {
    let { loadStatementsTask } = this.args.data;

    if (loadStatementsTask) {
      return await loadStatementsTask.linked().perform();
    } else {
      let filters = this.args.data.bankAccountId
        ? { bank_account_ids: [this.args.data.bankAccountId] }
        : undefined;

      return await this.store.query('statement', {
        organization_id: this.args.data.organizationId,
        filters,
      });
    }
  });
}
