export default {
  "tile-layout": "eM",
  "focus": "eP",
  "no-shadow": "el",
  "tile-layout--start-verification": "eX",
  "header": "eC",
  "headings": "ek",
  "management": "eJ",
  "logo": "es",
  "title": "eF title-4",
  "name": "eg title-4",
  "loading": "eD",
  "menu-item": "eV body-2",
  "close-account": "eL",
  "content": "eT"
};
