/* import __COLOCATED_TEMPLATE__ from './banner.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class AccountClosingBanner extends Component {
  @service segment;
  @service intl;
  @service subscriptionManager;

  get planName() {
    return this.subscriptionManager.currentPricePlan?.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  constructor() {
    super(...arguments);
    this.trackEvent();
  }

  trackEvent() {
    let {
      accountBalanceZero,
      noActiveSavingsAccount,
      balanceEqualToAuthorizedBalance,
      noPendingChecks,
    } = this.args.requirements;

    let requirementsFulfilled =
      accountBalanceZero &&
      noActiveSavingsAccount &&
      balanceEqualToAuthorizedBalance &&
      noPendingChecks;

    let status = 'Closing requirements KO';
    if (this.args.requester === 'qonto') {
      status = 'Qonto closure';
    } else if (requirementsFulfilled) {
      status = 'Closing requirements OK';
    }

    this.segment.track('company_profile_closure_banner', {
      status,
    });
  }

  get requirements() {
    return [
      {
        name: 'balance',
        title: this.intl.t('subscription.account-closing.requirements.balance'),
        isFullfilled: this.args.requirements.accountBalanceZero,
      },
      {
        name: 'pending-payments',
        title: this.intl.t('subscription.account-closing.requirements.pending-payments'),
        isFullfilled: this.noPendingPayments,
      },
      ...(!this.args.requirements.noActiveSavingsAccount
        ? [
            {
              name: 'term-deposit',
              title: this.intl.t('subscription.account-closing.requirements.no-term-deposit'),
              isFullfilled: false,
            },
          ]
        : []),
    ];
  }

  get closingDateFormatted() {
    return this.intl.formatDate(this.args.closingDate, { format: 'long' });
  }

  get noPendingPayments() {
    let { balanceEqualToAuthorizedBalance, noPendingChecks } = this.args.requirements;
    return balanceEqualToAuthorizedBalance && noPendingChecks;
  }
}
