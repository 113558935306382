/* import __COLOCATED_TEMPLATE__ from './application-cta.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CONNECT_FEATURE_LEVELS } from 'qonto/constants/connect';
import { HRIS_CONNECT_FEATURE } from 'qonto/constants/hris-integration';

export default class ApplicationCta extends Component {
  @service abilities;
  @service flowLinkManager;
  @service organizationManager;
  @service modals;
  @service segment;
  @service router;

  get isExternalBank() {
    return Boolean(this.args.application.bank_id);
  }

  get isIntegration() {
    let { integration_type, tray_solution_id, kombo_connect_provider_name } = this.args.application;

    return (
      this.isExternalBank ||
      integration_type === 'internal_integration' ||
      tray_solution_id ||
      kombo_connect_provider_name
    );
  }

  get canActivateIntegration() {
    if (this.isKomboConnectButCantUse) {
      return false;
    }

    switch (this.args.application.minimum_integration_level) {
      case CONNECT_FEATURE_LEVELS.silver:
        return this.abilities.can('access silver connect');
      case CONNECT_FEATURE_LEVELS.gold:
        return this.abilities.can('access gold connect');
      default:
        return true;
    }
  }

  get isKomboConnectButCantUse() {
    return (
      this.args.application.kombo_connect_provider_name &&
      this.abilities.cannot('use hris-integration')
    );
  }

  get upsellTrigger() {
    if (this.isKomboConnectButCantUse) return HRIS_CONNECT_FEATURE;
    return this.args.application.minimum_integration_level;
  }

  @action uninstallApplication() {
    if (this.args.trackCta) {
      this.args.trackCta('disconnect');
    }
    this.args.uninstallApplication();
  }

  @action installApplication(applicationSlug) {
    if (this.args.trackCta) {
      this.args.trackCta('connect');
    }
    this.args.installApplication(applicationSlug);
  }

  @action
  onUpsellClick() {
    if (this.args.trackCta) {
      this.args.trackCta('upsell');
    }

    if (this.args.trialInfo) {
      this.modals.open('discover/trial/confirm', {
        isFullScreenModal: true,
        trialInfo: this.args.trialInfo,
      });
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: {
          upsellTrigger: this.upsellTrigger,
        },
      });
    }
  }

  @action
  trackRedirect() {
    if (this.args.trackCta) {
      this.args.trackCta('redirect');
    }
  }
}
