export default {
  "row": "mo body-1",
  "active": "mn",
  "cell": "mi body-2",
  "empty": "mA",
  "no-padding": "mY",
  "cell-content": "mh",
  "debitor": "mf",
  "avatar": "mK",
  "debitor-details": "mG",
  "debitor-name": "mr",
  "reference": "mb",
  "amount": "my",
  "row--sidebar-open": "mU",
  "status": "mj"
};
