/* import __COLOCATED_TEMPLATE__ from './animation.hbs'; */
import Component from '@glimmer/component';

import { Avatar, LottiePlayer } from 'design-system-kit';

export default class QontoHubSetupAnimationComponent extends Component {
  lottiePlayer = LottiePlayer;

  avatar = Avatar;
}
