/* import __COLOCATED_TEMPLATE__ from './action-confirm-form.hbs'; */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ActionConfirmForm extends Component {
  @service userManager;

  @tracked isFormPristine = true;

  willDestroy() {
    super.willDestroy(...arguments);

    if (this.currentUser.currentPassword === undefined) return;

    this.currentUser.currentPassword = '';
  }

  get currentUser() {
    return this.userManager.currentUser;
  }

  get errorMessage() {
    if (this.isFormPristine) return null;

    let { errors } = this.currentUser;
    let message = errors
      ? get(errors, 'content.0.message') || get(errors, '0.message') || errors
      : undefined;
    return (message?.length && message) || '';
  }

  @action
  handleConfirm(e) {
    this.isFormPristine = false;
    this.args.setReasonError();
    e.preventDefault();

    return this.currentUser
      .confirmWithPassword()
      .then(() => this.args.confirm())
      .catch(() => {
        // ignore the error, we show the current password
        // error in the template
      });
  }
}
