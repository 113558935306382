/* import __COLOCATED_TEMPLATE__ from './aba.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class BeneficiaryTypeAbaComponent extends Component {
  @service intl;

  get routingNumberErrorMessage() {
    let { enableValidations, validations } = this.args;

    if (this.enableRoutingNumberValidation || enableValidations) {
      let message = validations.attrs.routingNumber?.message;
      if (message === 'invalid_routing_number') {
        return this.intl.t('validations.errors.invalid-routing-number');
      }

      return message;
    }
  }

  get accountNumberErrorMessage() {
    let { enableValidations, validations } = this.args;

    if (enableValidations) {
      let message = validations.attrs.accountNumber?.message;
      if (message === 'invalid_account_number') {
        return this.intl.t('validations.errors.invalid-account-number');
      }
      return message;
    }

    return null;
  }

  get enableRoutingNumberValidation() {
    return Boolean(this.args.routingNumber?.length) && this.validateRoutingNumberTask.performCount;
  }

  validateRoutingNumberTask = dropTask(async () => {
    let { validateTask, routingNumber } = this.args;
    if (Boolean(routingNumber?.length) && validateTask) await validateTask.perform('routingNumber');
  });
}
