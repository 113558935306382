export default {
  "cell": "dm",
  "placeholder-line": "dp",
  "counterparty": "dx",
  "counterparty-content": "dw",
  "member": "dN",
  "method": "dv",
  "operation": "do",
  "amount": "dn",
  "remove-transaction": "di",
  "empty": "dA"
};
