export default {
  "row": "HL body-1",
  "cell": "HT",
  "empty": "Hz",
  "amount": "Ha",
  "review": "HH",
  "cell-content": "HO",
  "icon": "Hm",
  "name": "Hp",
  "name-text": "Hx",
  "quick-actions": "Hw",
  "account-select": "HN"
};
