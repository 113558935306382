export default {
  "card": "EW",
  "header": "Eu",
  "budget-caption": "dS caption",
  "amount-spent-section": "dc",
  "amount-spent-icon-container": "dq",
  "amount-spent-title": "dZ title-3",
  "amount-budgeted-section": "dR",
  "amount-budgeted-title": "dQ title-3"
};
