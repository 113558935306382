export default {
  "wrapper": "wI",
  "header": "wt",
  "no-separator": "wM",
  "tabs": "wP",
  "tab": "wl body-1 overlay",
  "selected": "wX",
  "organization-list": "wC",
  "content": "wk",
  "empty-state": "wJ",
  "illustration": "ws",
  "body": "wF body-1",
  "cta": "wg btn btn--tertiary"
};
