/* import __COLOCATED_TEMPLATE__ from './conditions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ApprovalWorkflowRulesetAttrVisualizerConditions extends Component {
  @service intl;

  get conditions() {
    let amount = this.args.conditions[0]?.value || { value: null, currency: 'EUR' };

    return this.intl.t('approval-workflows.ruleset.conditions.gt', {
      amount: this.intl.formatNumber(amount.value, {
        currency: amount.currency,
        style: 'currency',
      }),
    });
  }
}
