import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FormFieldsAmountField from '@qonto/ui-kit/components/form-fields/amount-field';
import { VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import styles from './condition.strict-module.css';
import ApprovalWorkflowConditionInfo from './condition/info.js';
export default class ApprovalWorkflowCondition extends Component {
    @service
    intl;
    VALIDATION_ERROR_KEYS = {
        [VALIDATION_ERRORS.CONDITION_AMOUNT_EMPTY]: this.intl.t('approval-workflows.errors.conditions.value.empty'),
        [VALIDATION_ERRORS.CONDITION_AMOUNT_LOWER]: this.intl.t('approval-workflows.errors.conditions.value.lower'),
        [VALIDATION_ERRORS.CONDITION_AMOUNT_HIGHER]: this.intl.t('approval-workflows.errors.conditions.value.higher'),
        [VALIDATION_ERRORS.CONDITION_AMOUNT_SAME]: this.intl.t('approval-workflows.errors.conditions.value.same')
    };
    get hasErrors() {
        return this.args.errors?.length > 0;
    }
    get errorMessage() {
        if (this.hasErrors) {
            let amountError1 = this.VALIDATION_ERROR_KEYS[this.args.errors[0].message];
            if (amountError1) return amountError1;
        }
        return null;
    }
    get isNotFirstCondition() {
        return this.args.index > 0;
    }
    get amountInputLabel() {
        if (this.isNotFirstCondition) return this.intl.t('approval-workflows.form.conditions.gte');
        return this.intl.t('approval-workflows.form.conditions.gt');
    }
    get shouldDisplayCaption() {
        let { nextRulesetConditionAmount: nextRulesetConditionAmount1 } = this.args;
        if (nextRulesetConditionAmount1) {
            let nextRulesetConditionAmountToInt1 = parseFloat(nextRulesetConditionAmount1, 10);
            return nextRulesetConditionAmountToInt1 !== 0 && !this.hasErrors;
        }
        return false;
    }
    static{
        template(`
    <div class={{styles.amount-field}} data-test-approval-workflow-form-condition-amount={{@index}}>
      <FormFieldsAmountField
        @label={{this.amountInputLabel}}
        @disabled={{@disabled}}
        @updateAmount={{@onUpdate}}
        @amount={{@condition.value}}
        @errorMessage={{this.errorMessage}}
        @min={{0}}
        {{on 'focusout' @validate}}
        data-test-workflow-form-condition-amount-step
        ...attributes
      />
      <ApprovalWorkflowConditionInfo
        @isVisible={{this.shouldDisplayCaption}}
        @conditionAmount={{@condition.value}}
        @nextRulesetConditionAmount={{@nextRulesetConditionAmount}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
