export default {
  "wrapper": "xE",
  "badge-trial": "xd",
  "header": "xI",
  "list": "xt body-2",
  "main": "xM",
  "card": "xP",
  "container": "xl",
  "header-button": "xX",
  "error-text": "xC",
  "mt-16": "xk"
};
