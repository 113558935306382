/* import __COLOCATED_TEMPLATE__ from './nickname.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CardSettingsNicknameComponent extends Component {
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service sentry;

  @tracked newNickname = this.args.nickname;
  @tracked error;

  @action updateNicknameField(value) {
    this.newNickname = value;
  }

  onSubmitTask = dropTask(async () => {
    if (!this.newNickname) {
      this.error = this.intl.t('cards.settings.nickname.error-empty');
    } else if (this.newNickname.length > 40) {
      this.error = this.intl.t('cards.settings.nickname.error-too-long', { count: 40 });
    } else {
      try {
        await this.args.onSaveTask.perform(this.newNickname);
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      }
    }
  });
}
