/* import __COLOCATED_TEMPLATE__ from './qonto-pilot.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { HOTKEYS, modifierKey } from 'qonto/constants/qonto-pilot';
import { isMac } from 'qonto/utils/is-mac';

export default class DualNavQontoPilotMenuComponent extends Component {
  @service modals;
  @service segment;
  @service qontoPilotService;

  hotkey = HOTKEYS;
  modifierKey = modifierKey(isMac());

  constructor() {
    super(...arguments);
    this.setEvents();
  }

  /**
   * Listener that opens the Qonto Pilot modal if the user presses T
   */
  setEvents() {
    window.addEventListener('keydown', event => {
      let isEditable = document.activeElement.isContentEditable;

      if ((isMac() && event.metaKey) || (!isMac() && event.ctrlKey)) {
        if (
          Object.keys(HOTKEYS).includes(event.key.toUpperCase()) &&
          !isEditable &&
          document.activeElement === document.body &&
          (!this.qontoPilotService.modal || this.qontoPilotService.modal?.isClosing) // prevents the opening of a second modal
        ) {
          // prevents overriding unrelated native shortcut on Linux/Firefox
          if (!event.shiftKey) {
            event.preventDefault();
          }
          this.openQontoPilotModal(HOTKEYS[event.key.toUpperCase()]);
        }
      }
    });
  }

  @action
  openQontoPilotModal(actionValue) {
    this.segment.track('qontopilot_transfer_opened');
    this.qontoPilotService.modal = this.modals.open(
      'qonto-pilot/modal',
      { action: actionValue },
      {
        className: 'top-modal qonto-pilot',
      }
    );
  }

  get shouldShowItemDetails() {
    return this.args.state === DUAL_NAV_STATES.EXPANDED;
  }
}
