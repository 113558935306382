/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import LOCALE_ARRAY from 'qonto/constants/locales';
import computedDomiciliation from 'qonto/utils/computed-domiciliation';
import { formatIban } from 'qonto/utils/format-input';

export default class BankAccountShareComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service featuresManager;
  @service userManager;

  @reads('featuresManager.flags.accountDetailsIntermediaryBic') isIntermediaryBicEnabled;
  @reads('userManager.currentUser') user;

  @action
  displayCopyToast() {
    this.toastFlashMessages.toastInfo(this.intl.t('bank_accounts.notifications.account_details'));
    this.segment.track('account_details_copied');
  }

  get currentUserLocal() {
    let locale = LOCALE_ARRAY.find(item => item.code === this.user.localeObject.code);
    return locale ? locale.code : 'en';
  }

  get accountToClipboard() {
    let { bankAccount, organization } = this.args;

    return [
      `${this.intl.t('bank_accounts.clipboard_labels.beneficiary')} ${organization.name}`,
      `${this.intl.t('bank_accounts.clipboard_labels.iban')} ${formatIban(bankAccount.iban)}`,
      `${this.bicLabel} ${bankAccount.bic}`,
      this.correspondentBankLabel,
      `${this.intl.t('bank_accounts.clipboard_labels.owner_address')} ${
        organization.address.fullAddress
      }`,
      ``,
      this.intl.t('bank_accounts.clipboard_labels.additional_details'),
      `${this.intl.t('bank_accounts.clipboard_labels.domiciliation')} ${this.bankDomiciliation}`,
      `${this.intl.t('bank_accounts.clipboard_labels.bank')} ${bankAccount.bankCode}`,
      bankAccount.branchCode &&
        `${this.intl.t('bank_accounts.clipboard_labels.branch')} ${bankAccount.branchCode}`,
      `${this.intl.t('bank_accounts.clipboard_labels.account')} ${bankAccount.accountNumber}`,
      bankAccount.controlDigits &&
        `${this.intl.t('bank_accounts.clipboard_labels.key')} ${bankAccount.controlDigits}`,
    ]
      .filter(v => v !== null)
      .join(`\n`);
  }

  get bicLabel() {
    return this.args.bankAccount.isSwiftEnabled
      ? this.intl.t('bank_accounts.clipboard_labels.bic_swift')
      : this.intl.t('bank_accounts.clipboard_labels.bic');
  }

  get correspondentBankLabel() {
    return this.args.bankAccount.isSwiftEnabled
      ? this.intl.t('bank_accounts.clipboard_labels.correspondent_bank')
      : null;
  }

  @computedDomiciliation() bankDomiciliation;
}
