export default {
  "wrapper": "Oa",
  "header": "OH",
  "status": "OO",
  "close-button": "Om",
  "slug": "Op",
  "category": "Ox",
  "category-title": "Ow",
  "activity-tag-select": "ON",
  "footer": "Ov"
};
