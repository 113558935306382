/* import __COLOCATED_TEMPLATE__ from './suspend.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { CARD_LOCK_REASONS } from 'qonto/constants/cards';

export default class CardModalsSuspendComponent extends Component {
  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service cardsManager;

  @tracked isReasonError = false;
  @tracked lockReason = null;

  get shouldShowReasonError() {
    return this.isReasonError && !this.lockReason;
  }

  @action
  setReasonError() {
    if (!this.lockReason) {
      this.isReasonError = true;
    }
  }

  lockCardTask = dropTask(async (close, card) => {
    if (!this.lockReason) return;

    try {
      await card.lock(this.lockReason);
      if (card.isMetal) {
        await this.cardsManager.updateMembershipFeatureFlagsTask.perform(card);
      }
      this.toastFlashMessages.toastInfo(this._getLockToastMessage(this.lockReason));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      this.segment.track(`card_${this.lockReason}_confirmed`);
      close();
    }
  });

  _getLockToastMessage(reason) {
    return reason === CARD_LOCK_REASONS.STOLEN
      ? this.intl.t('toasts.cards.stolen')
      : this.intl.t('toasts.cards.lost');
  }
}
