export default {
  "row": "dY body-1",
  "cell": "dh",
  "counterparty": "df",
  "counterparty-icon": "dK",
  "avatar": "dG",
  "icon": "dr",
  "counterparty-content": "db",
  "item-counterparty-name": "dy",
  "counterparty-name": "dU body-1",
  "counterparty-status": "dj",
  "member": "dW",
  "method": "du",
  "operation-date": "IS",
  "amount": "Ic",
  "remove-transaction": "Iq",
  "menu-item": "IZ",
  "remove": "IR",
  "tooltip": "IQ",
  "empty": "Ie"
};
