export default {
  "attachment-viewer": "Rd",
  "sidebar": "RI",
  "attachment-viewer-details": "Rt",
  "attachment-viewer-filename": "RM body-2",
  "attachment-viewer-actions": "RP",
  "dropzone": "Rl",
  "dropzone-visible": "RX",
  "attachment-main": "RC"
};
