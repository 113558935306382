/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* eslint-disable @qonto/no-async-action */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from 'design-system-kit';

const SLIDES_PER_PAGE = 3;

export default class DiscoverOverviewComponent extends Component {
  badgeHighlight = BadgeHighlight;

  @service segment;
  @service subscriptionManager;

  get isSwipable() {
    return this.args.slides?.length > SLIDES_PER_PAGE;
  }

  @action
  async onClick() {
    await this.args.close?.();
    await this.args.onClick?.();
  }
}
