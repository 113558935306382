export default {
  "aside": "Ra",
  "aside-section": "RH",
  "aside-title": "RO caption-bold",
  "placeholder": "Rm",
  "footer": "Rp",
  "cancel-button": "Rx",
  "attachment-date": "Rw body-2",
  "attachment-item": "RN"
};
