/* import __COLOCATED_TEMPLATE__ from './allocation-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { EXERCISE_KINDS, METER_AMOUNTS_THRESHOLDS } from 'qonto/constants/budget';
import { Meter } from 'qonto/react/components/meter';

export default class BudgetSidebarAllocationDetailsComponent extends Component {
  @service intl;

  amountsThresholds = METER_AMOUNTS_THRESHOLDS;
  meter = Meter;

  get meterValue() {
    let { period } = this.args.allocatedBudget;
    return parseFloat(period.amountSettled.value);
  }

  get meterMax() {
    let { period } = this.args.allocatedBudget;
    return parseFloat(period.amountBudgetedValue);
  }

  get displayRemainingAmount() {
    let { period } = this.args.allocatedBudget;
    return period.amountRemainingValue > 0;
  }

  get status() {
    let { budget, period } = this.args.allocatedBudget;
    let overBudget = period.amountRemainingValue <= 0;

    if (budget.exerciseKind === EXERCISE_KINDS.MONTHLY) {
      if (overBudget) {
        return this.intl.t('team-budget.allocation-details.status.no-budget-remaining-monthly', {
          month: period.name,
        });
      } else {
        return this.intl.t('team-budget.allocation-details.status.remaining-monthly', {
          month: period.name,
        });
      }
    } else {
      let periodEndDate = dateToken({
        date: period.endDate,
        locale: this.intl.locale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      });

      if (overBudget) {
        return this.intl.t('team-budget.allocation-details.status.no-budget-remaining-global', {
          periodEndDate,
        });
      } else {
        return this.intl.t('team-budget.allocation-details.status.remaining-global', {
          periodEndDate,
        });
      }
    }
  }

  get noBudgetedPeriodDisclaimer() {
    let { period } = this.args.allocatedBudget;

    return this.intl.t('team-budget.allocation-details.no-period.disclaimer', {
      budget_period_name: period.name,
    });
  }

  @action
  formatAmount(amount, currency = 'eur') {
    let integer = parseInt(amount, 10);
    let difference = Number(amount) - integer;
    let numberToFormat = difference > 0 ? amount : integer;

    return this.intl.formatNumber(numberToFormat, {
      minimumFractionDigits: difference > 0 ? 2 : 0,
      currency,
      style: 'currency',
    });
  }
}
