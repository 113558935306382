/* import __COLOCATED_TEMPLATE__ from './representative-infos.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';

export const FIELDS = [
  'firstName',
  'lastName',
  'email',
  'nationality',
  'birthdate',
  'birthCity',
  'birthZipcode',
  'birthCountry',
  'address.country',
  'proofOfIdentity',
  'proofOfIdentitySubtype',
];

const POI_TYPES = ['eu_id', 'eu_passport', 'resident_card'];

export default class LegalEntityRepresentativeInfosComponent extends Component {
  @service intl;
  @service modals;
  @service store;
  @service toastFlashMessages;
  @service organizationManager;
  @service sentry;

  @reads('args.context.stakeholder.didValidate') isValidationEnabled;
  @reads('args.context.stakeholder.validations.attrs.birthdate') birthDateValidation;

  poiTypes = POI_TYPES;
  poiMaxSize = TEMPLATE_VALUES.poiMaxSize;

  get poiTypesTranslationsLookup() {
    let { legalCountry } = this.organizationManager.organization;

    return {
      eu_id: this.intl.t('doc-types.poi.eu-id', { legalCountry }),
      eu_passport: this.intl.t('doc-types.poi.eu-passport'),
      resident_card: this.intl.t('doc-types.poi.resident-card'),
    };
  }

  get birthDateErrorMessage() {
    return this.isValidationEnabled ? this.birthDateValidation.message : null;
  }

  get dropzoneText() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, this.poiMaxSize),
    });
  }

  get localFiles() {
    return this.args.context?.stakeholder?.proofOfIdentity?.files;
  }

  submitTask = dropTask(async stakeholder => {
    let { validations } = await stakeholder.validate({ on: FIELDS });

    // for error diplay purpose
    stakeholder.set('didValidate', true);

    if (validations.isValid) {
      try {
        await this.args.context.saveLegalEntityTask.linked().perform(this.args.context);
        this.args.transitionToNext();
      } catch (error) {
        if (error instanceof ForbiddenError) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          this.sentry.captureException(error);
        }
      }
    }
  });

  @action
  handleSelectType(stakeholder, docSubtype) {
    if (stakeholder.proofOfIdentity) {
      stakeholder.proofOfIdentity.docSubtype = docSubtype;
    } else {
      let proofOfIdentity = this.store.createRecord('document', {
        docSubtype,
        docType: 'poi',
        files: [],
      });
      if (!stakeholder.documents.includes(proofOfIdentity)) {
        stakeholder.documents.push(proofOfIdentity);
      }
    }
  }

  @action
  handleAddDocumentToStakeholder(stakeholder, docSubtype, file) {
    if (stakeholder.proofOfIdentity) {
      if (!stakeholder.proofOfIdentity.files.includes(file)) {
        stakeholder.proofOfIdentity.files.push(file);
      }
      // Since `proofOfIdentity` is a getter on the `documents`,
      // we have to notify that CP needs to read from it again.
      stakeholder.notifyPropertyChange('documents');
    } else {
      let proofOfIdentity = this.store.createRecord('document', {
        docSubtype,
        docType: 'poi',
        files: [file],
      });
      if (!stakeholder.documents.includes(proofOfIdentity)) {
        stakeholder.documents.push(proofOfIdentity);
      }
    }
  }

  @action
  handleRemoveFileFromDocument(stakeholder, file) {
    let { files } = stakeholder.proofOfIdentity;
    let index = files.indexOf(file);
    files.splice(index, 1);

    if (files.length === 0) {
      return stakeholder.proofOfIdentity.destroyRecord();
    }
  }

  @action
  handleOpenFilePreviewModal(stakeholder, file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
      model: stakeholder,
    });
  }

  @action
  willDestroy() {
    super.willDestroy();

    let { context } = this.args;

    if (context.stakeholder?.isNew) {
      context.resetStakeholder(context);
    }
  }
}
