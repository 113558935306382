export default {
  "not-active": "EF",
  "title": "Eg",
  "details": "ED",
  "detail": "EV",
  "text-secondary": "EL",
  "text-primary-a": "ET",
  "amounts": "Ez",
  "contents-container": "Ea",
  "contents": "EH",
  "menu-item": "EO",
  "archive": "Em",
  "amount-settled": "Ep",
  "shadow": "Ex",
  "name": "Ew title-4",
  "tooltip": "EN"
};
