/* import __COLOCATED_TEMPLATE__ from './account-aggregation-promo-box.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from 'design-system-kit';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const LOCAL_STORAGE_KEY = 'account-aggregation-promo-box-dismissed';

export default class DualNavAccountAggregationPromoBoxComponent extends Component {
  badge = Badge;

  @service router;
  @service segment;

  get shouldDisplay() {
    return this.args.shouldDisplay && !safeLocalStorage.getItem(LOCAL_STORAGE_KEY);
  }

  get origin() {
    return this.router.currentRoute.name || DEFAULT_ORIGIN_URL;
  }

  @action
  handleOnClick() {
    safeLocalStorage.setItem(LOCAL_STORAGE_KEY, Date.now());
    this.segment.track('account-aggregation-promo-box_cta_click');
  }
}
