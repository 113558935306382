/* import __COLOCATED_TEMPLATE__ from './promotion.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { getPromotionRecommendationsConfig } from 'qonto/constants/promotion';
import { SUBSCRIPTION_STATUS } from 'qonto/constants/subscriptions';

export default class PromotionRecommendationComponent extends Component {
  @service intl;
  @service abilities;
  @service subscriptionManager;

  supportedRoutes = Object.keys(getPromotionRecommendationsConfig(this.intl));

  get shouldRender() {
    let { activeSubMenuItem, groups } = this.args;
    let activeSubMenuItemId = activeSubMenuItem?.id;
    if (
      this.subscriptionManager.currentSubscription?.status !== SUBSCRIPTION_STATUS.ACTIVE ||
      !groups ||
      !activeSubMenuItemId ||
      !this.supportedRoutes.includes(activeSubMenuItemId) ||
      !this.abilities.can('update subscription')
    ) {
      return false;
    }

    let isCurrentSubMenu = groups.find(group =>
      group.items.some(item => item.id === activeSubMenuItemId)
    );

    return isCurrentSubMenu && Boolean(this.routeConfig);
  }

  get routeConfig() {
    let subMenuId = this.args.activeSubMenuItem?.id;
    return subMenuId && getPromotionRecommendationsConfig(this.intl)[subMenuId];
  }

  get variation() {
    return variation('feature--boolean-soft-quota-promotion');
  }

  get shouldRenderTransfers() {
    return !this.variation && this.args.activeSubMenuItem?.id === 'transfers';
  }
}
