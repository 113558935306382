/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';

export default class ConnectionsBanksSidebarComponent extends Component {
  get showCTA() {
    let { showDisconnect, showReconnect } = this.args;

    return Boolean(showDisconnect) || Boolean(showReconnect);
  }
}
