/* import __COLOCATED_TEMPLATE__ from './not-eligible-empty-state.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class DirectDebitCollectionsNotEligibleEmptyState extends Component {
  @service segment;

  constructor() {
    super(...arguments);

    let { emptyStateRevampOptions } = this.args;

    if (emptyStateRevampOptions) {
      let trackingData = emptyStateRevampOptions.tracking?.({
        isClickEvent: false,
        isEmptyState: true,
      });

      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }
}
