/* import __COLOCATED_TEMPLATE__ from './application-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Avatar, BadgeHighlight } from 'design-system-kit';

import { CONNECT_FEATURE_LEVELS } from 'qonto/constants/connect';

export default class ApplicationCardComponent extends Component {
  avatar = Avatar;
  badgeHighlight = BadgeHighlight;

  @service segment;
  @service intl;
  applicationData = null;

  constructor() {
    super(...arguments);
    this.applicationData = this.args.application?.data;
  }

  get showBIC() {
    return Boolean(this.applicationData?.bank_bic) && this.args.compact;
  }

  get higherPlanTooltip() {
    let { minimum_integration_level } = this.applicationData;

    if (minimum_integration_level === CONNECT_FEATURE_LEVELS.silver)
      return this.intl.t('qonto-hub.connect.to-discover-tooltip.silver');

    if (minimum_integration_level === CONNECT_FEATURE_LEVELS.gold)
      return this.intl.t('qonto-hub.connect.to-discover-tooltip.gold');
  }

  get collectionTag() {
    return this.applicationData?.integration_type === 'partnerships'
      ? this.intl.t('connect.category.partnerships')
      : this.intl.t('connect.category.integrations');
  }

  @action fireTrackingEvent() {
    let { filteredBy, appliedFilters, organization } = this.args;
    let { name, integration_type, tray_solution_id } = this.applicationData;

    this.segment.track('connect_app_card_clicked', {
      legalCountry: organization.legalCountry,
      integrationName: name,
      integrationType: integration_type,
      solutionId: tray_solution_id,
      filteredBy,
      ...(appliedFilters && {
        category: appliedFilters.category?.slug,
        type: appliedFilters.type?.slug,
        designedFor: appliedFilters.stakeholder?.slug,
      }),
    });
  }
}
