export default {
  "row": "ZS body-1",
  "active": "Zc",
  "cell": "Zq",
  "empty": "ZZ",
  "ellipsis": "ZR",
  "text-secondary": "ZQ",
  "row-sidebar": "Ze",
  "cell-conditions": "ZB",
  "animated": "ZE",
  "fadein-item": "Zd",
  "animated-cell": "ZI",
  "pr-24": "Zt",
  "rule-with-error": "ZM",
  "icon": "ZP"
};
