/* import __COLOCATED_TEMPLATE__ from './select-step.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from 'design-system-kit';

export default class BankAccountsSelectStep extends Component {
  lottiePlayer = LottiePlayer;

  @service organizationManager;

  @tracked searchValue = '';

  get listOptions() {
    let { activeSortedQontoAccounts } = this.organizationManager.organization;
    let isEnabledFn = this.args.isEnabledFn || (() => true);

    return activeSortedQontoAccounts
      .filter(({ isRemunerated }) => !isRemunerated)
      .map(account => {
        return { account, isEnabled: isEnabledFn(account) };
      });
  }

  get filteredListOptions() {
    let { searchValue, listOptions } = this;
    searchValue = searchValue?.trim().toLowerCase();

    if (!searchValue) {
      return listOptions;
    }

    return listOptions.filter(({ account: { name } }) => name.toLowerCase().includes(searchValue));
  }

  @action
  selectAccount({ account, isEnabled }) {
    if (isEnabled) {
      let { transitionToNext } = this.args;
      this.args.setAccount(account);
      transitionToNext();
    }
  }
}
