export default {
  "dual-nav": "xW",
  "header": "xu",
  "expanded": "wS",
  "collapsed": "wc",
  "responsive": "wq",
  "overlay": "wZ",
  "visible": "wR",
  "footer": "wQ"
};
