/* import __COLOCATED_TEMPLATE__ from './permissions-details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ConnectionsPermissionsDetailsComponent extends Component {
  @service intl;

  get permissions() {
    return [
      this.intl.t('settings.connections.sidebar.qonto-permissions.item1'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.item2'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.item3'),
    ];
  }
}
