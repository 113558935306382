export default {
  "wrapper": "Nd",
  "active": "NI",
  "expanded": "Nt",
  "fade-in": "NM",
  "hidden": "NP",
  "visible": "Nl",
  "item-list": "NX",
  "item-list-slide-down": "NC",
  "dropdown-trigger": "Nk overlay",
  "settings-dropdown-slide-down": "NJ",
  "responsive": "Ns",
  "is-selected": "NF",
  "is-active": "Ng",
  "dropdown": "ND"
};
