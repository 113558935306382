/* import __COLOCATED_TEMPLATE__ from './avatar-confirm-dialog.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import LocalFile from 'qonto/objects/local-file';

export default class AvatarConfirmDialog extends Component {
  spinner = Spinner;

  @service intl;

  @tracked file;

  get title() {
    return this.args.data.title || this.intl.t('btn.upload_photo');
  }

  get cancelText() {
    return this.args.data.cancelText || this.intl.t('btn.cancel');
  }

  get ctaText() {
    return this.args.data.ctaText || this.intl.t('btn.submit');
  }

  @action
  cancelEvent() {
    this.args.close?.();

    return this.args.data.cancelEvent?.() || (() => {});
  }

  @action
  handleFinalFile(file) {
    this.file = file;
  }

  handleConfirmTask = dropTask(async () => {
    let { data, close } = this.args;
    let { avatarCandidate, confirmTask, hideCropper } = data;

    if (hideCropper) {
      this.file = LocalFile.create({
        fileName: avatarCandidate.fileName,
        fileContentType: 'image/png',
        fileUrl: avatarCandidate.fileUrl,
      });
    }
    await confirmTask.perform(close, this.file);
  });
}
