/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Spinner, Tag } from 'design-system-kit';
import { dropTask, rawTimeout, restartableTask } from 'ember-concurrency';
import { equal, or, reads } from 'macro-decorators';

import HIDDEN_CARD_NUMBERS from 'qonto/routes/cards/index/controller';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const NUMBERS_HIDE_DELAY_MS = 30000;

export default class CardSidebarHeader extends Component {
  spinner = Spinner;

  tag = Tag;

  @service abilities;
  @service cardsManager;
  @service intl;
  @service toastFlashMessages;
  @service organizationManager;
  @service segment;
  @service router;
  @service flowLinkManager;

  @or('args.card.ghost') disabled;
  @reads('organizationManager.membership') membership;
  @equal('organizationManager.membership.kycSubmitted', false) isKycLess;

  get showAccountName() {
    let { bankAccount } = this.args.card;
    return (
      !this.disabled &&
      Boolean(bankAccount?.name) &&
      bankAccount.organization.hasMultipleBankAccounts
    );
  }

  get areActionButtonsShown() {
    let { card } = this.args;

    let isActionButtonShown =
      this.abilities.can('reveal PAN card', card) ||
      this.abilities.can('lock card', card) ||
      this.abilities.can('see PIN card', card);

    if (card.isFlash) {
      return isActionButtonShown;
    }

    let isKycDone = this.membership?.kycSubmitted && !this.membership?.kycPending;

    return isActionButtonShown && isKycDone;
  }

  get holderFullName() {
    let { card } = this.args;
    return card.embossedName || card.holder.get('fullName');
  }

  showNumbersTask = dropTask(async card => {
    try {
      let { pan, cvv } = card.isQcp
        ? await this.cardsManager.getEncryptedNumbersTask.perform(card.id)
        : await card.getNumbers();

      this.args.toggleRevealCardNumbers({ pan, cvv, isRevealed: true });

      this.hideNumbersWithDelayTask.perform().catch(ignoreCancelation);
    } catch (error) {
      let errors = error?.errors;
      if (hasMFAError(errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  @action
  closeSidebar(cardId) {
    this.args.closeSidebar();
    document.querySelector(`[data-item-id="${cardId}"]`)?.focus();
  }

  hideNumbersWithDelayTask = restartableTask(async () => {
    await rawTimeout(NUMBERS_HIDE_DELAY_MS);
    this.args.toggleRevealCardNumbers(HIDDEN_CARD_NUMBERS);
  });

  get shouldDisplayStartVerificationButton() {
    let {
      isCardHolder,
      card: { isPhysical, pinSet },
    } = this.args;
    let shouldHideSetPinButton = !isPhysical || (isPhysical && pinSet);

    return isCardHolder && this.isKycLess && shouldHideSetPinButton;
  }

  @action
  startFourthlineFlow() {
    let { personalInfoProvided } = this.organizationManager.membership;
    if (personalInfoProvided) {
      this.router.transitionTo('kyc.start', {
        queryParams: {
          redirectRoute: this.router.currentURL,
          trackingEventOrigin: 'card_flow',
        },
      });
    } else {
      this.flowLinkManager.transitionTo({
        name: 'management-kyc',
        stepId: 'user-info',
      });
    }
  }

  @action
  startSetPinFlow() {
    this.segment.track('physical_card_set_pin_start');

    this.flowLinkManager.transitionTo({
      name: 'card-set-pin',
      stepId: 'set-pin',
      queryParams: { id: this.args.card.id },
    });
  }

  get kycNotApproved() {
    let { isPhysical, isFlash } = this.args.card;
    return this.membership.kycPending && this.args.isCardHolder && (isPhysical || isFlash);
  }
}
