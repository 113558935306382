export default {
  "header-cell": "qA",
  "empty": "qY",
  "header-type": "qh",
  "header-rules": "qf",
  "header-date": "qK",
  "header-action": "qG",
  "header-content": "qr caption-bold",
  "active": "qb",
  "row-sidebar": "qy",
  "hidden": "qU",
  "animated": "qj",
  "fadein-item": "qW",
  "animated-cell": "qu"
};
