import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import FormField from '@qonto/ui-kit/components/form-fields/form-field';
import PowerSelectCustomsMultipleOption from '@qonto/ui-kit/components/power-select-customs/multiple/option';
import PowerSelectCustomsSearch from '@qonto/ui-kit/components/power-select-customs/search';
import PowerSelectCustomsTrigger from '@qonto/ui-kit/components/power-select-customs/trigger';
import { t } from 'ember-intl';
import PowerSelectMultiple from 'ember-power-select/components/power-select-multiple';
import { VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import styles from './approvers-selector.strict-module.css';
import SelectorOption from './approvers-selector/selector-option';
export default class ApprovalWorkflowFormStepSelectorsApproversSelector extends Component {
    @service
    intl;
    @tracked
    state;
    @tracked
    hasScrolledToSelection = false;
    @action
    registerAPI(state1) {
        if (state1) {
            let { lastApproverSelected: lastApproverSelected1 } = this.args;
            this.state = state1;
            if (lastApproverSelected1) {
                let option1 = state1.options.find((option1)=>option1.key === lastApproverSelected1.key);
                if (option1 && !this.hasScrolledToSelection) {
                    state1.actions.scrollTo(option1);
                    this.hasScrolledToSelection = true;
                }
            }
        }
    }
    get approversSelection() {
        return this.args.approversOptions?.filter((approver1)=>approver1.selected);
    }
    get errorMessage() {
        if (this.args.approversErrors) {
            switch(this.args.approversErrors[0].message){
                case VALIDATION_ERRORS.STEP_APPROVERS_EMPTY:
                    return this.intl.t('approval-workflows.errors.steps.approver.empty');
                case VALIDATION_ERRORS.MEMBERSHIP_NO_PERMISSION:
                case VALIDATION_ERRORS.MEMBERSHIP_NOT_ACTIVE:
                case VALIDATION_ERRORS.MEMBERSHIP_NOT_FOUND:
                case VALIDATION_ERRORS.NO_ACTIVE_USER_WITH_ROLE:
                case VALIDATION_ERRORS.INACTIVE_MEMBERSHIP:
                case VALIDATION_ERRORS.NO_PERMISSION_TO_REVIEW:
                case VALIDATION_ERRORS.NO_ACTIVE_USERS:
                case VALIDATION_ERRORS.UNSUPPORTED_ROLE:
                    return this.intl.t('approval-workflows.errors.steps.approver.change');
            }
        }
        return null;
    }
    searchApprovers = (query1)=>{
        let filteredResults1 = this.args.approversOptions.filter((option1)=>{
            let optionValue1 = option1.value?.toLowerCase();
            let queryValue1 = query1.toLowerCase();
            return optionValue1?.includes(queryValue1);
        });
        return filteredResults1;
    };
    static{
        template(`
    <FormField ...attributes @message={{this.errorMessage}} @messageLevel='error'>
      <:field as |field|>
        <PowerSelectMultiple
          @registerAPI={{this.registerAPI}}
          @initiallyOpened={{@shouldApproversSelectorBeOpen}}
          @ariaDescribedBy={{field.messageId}}
          @ariaLabelledBy={{field.labelId}}
          @allowClear={{true}}
          @closeOnSelect={{false}}
          @dropdownClass='power-select-custom fit-content {{styles.multiple-select-dropdown-custom}}'
          class={{styles.approver-select-trigger}}
          @triggerClass='power-select-customs-trigger block {{if
            this.errorMessage
            "error has-danger"
          }} {{styles.trigger}}'
          @searchField='name'
          @searchEnabled={{true}}
          @searchPlaceholder={{t 'labels.search'}}
          @search={{this.searchApprovers}}
          @beforeOptionsComponent={{component PowerSelectCustomsSearch}}
          @triggerComponent={{component PowerSelectCustomsTrigger selected=this.approversSelection}}
          @renderInPlace={{true}}
          @placeholder={{t 'approval-workflows.form.placeholder.approver'}}
          @onChange={{fn @onApproversChange @approversOptions}}
          @onClose={{@handleApproversSelectorClose}}
          @options={{@approversOptions}}
          @selected={{this.approversSelection}}
          data-test-step-selectors-approvers-selector
          as |option select|
        >
          <PowerSelectCustomsMultipleOption @select={{select}} @key={{option.key}}>
            <SelectorOption @option={{option}} />
          </PowerSelectCustomsMultipleOption>
        </PowerSelectMultiple>
      </:field>
    </FormField>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
