export default {
  "wrapper": "pi",
  "modal": "pA",
  "close-button": "pY",
  "content": "ph",
  "title": "pf title-1",
  "description": "pK body-2",
  "slides-container": "pG",
  "slide": "pr"
};
