/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
import { guidFor } from '@ember/object/internals';
import { run } from '@ember/runloop';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class CollapseOptionOptionComponent extends Component {
  optionId = `${guidFor(this)}-option-id`;

  get isHidden() {
    return this.args.collapsed && !this.isSelected;
  }

  get isSelected() {
    return this.args.value === this.args.selectedValue;
  }

  get isNotDisabled() {
    return !this.args.disabled;
  }

  get showClearButton() {
    return this.args.clearable && this.isSelected && this.isNotDisabled;
  }

  selectOptionTask = dropTask(async () => {
    if (this.args.disabled) return;

    let { clearable, value } = this.args;

    if (!this.isSelected) {
      if (value !== 0 && clearable) {
        run(() => {
          let optionElement = document.getElementById(this.optionId);
          optionElement.style.transform = `translateX(${-optionElement.offsetLeft}px) scaleX(1.7)`;
        });
      }

      await this.args.select({
        value,
        clearable,
      });

      if (value !== 0 && clearable) {
        run(() => {
          let optionElement = document.getElementById(this.optionId);
          optionElement.style.transform = '';
          optionElement.style.transition = '';
        });
      }
    } else {
      // eslint-disable-next-line ember/no-array-prototype-extensions
      this.args.clear({ value });
    }
  });
}
