export default {
  "item": "NS",
  "qonto-pilot-nav-item": "Nc",
  "wrapper": "Nq",
  "collapsed": "NZ",
  "label": "NR body-1",
  "icon-wrapper": "NQ",
  "icon": "Ne",
  "key": "NB",
  "keys": "NE"
};
