/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ApprovalWorkflowsTableItem extends Component {
  @service flowLinkManager;
  @service segment;
  @service intl;

  generateConditionsString(conditions) {
    let allConditionsString = '';

    conditions.forEach(condition => {
      let singleConditionString = this.conditionString(condition);
      if (allConditionsString) {
        allConditionsString = `${allConditionsString}, ${singleConditionString}`;
      } else {
        allConditionsString = `${singleConditionString}`;
      }
    });

    return allConditionsString;
  }

  conditionString({ currency, value }) {
    return this.intl.t('approval-workflows.ruleset.conditions.gte', {
      amount: this.intl.formatNumber(value, {
        currency,
        style: 'currency',
      }),
    });
  }

  get conditions() {
    let { workflow } = this.args;
    let conditions = workflow.rulesets?.map(ruleset => ruleset.conditions[0]?.value);

    return this.generateConditionsString(conditions);
  }

  get hasErrors() {
    let { workflow } = this.args;
    return workflow.workflowErrors?.length > 0;
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  onRowClick(id) {
    this.args.highlightItem(id);
  }

  @action
  onEditWorkflow() {
    this.segment.track('approval-section_workflow-list-edit-button_clicked');
    this.flowLinkManager.transitionTo({
      name: 'approval-workflows',
      stepId: 'builder',
      queryParams: { id: this.args.workflow.id },
    });
  }

  get shouldDisplayUpdateDate() {
    return !this.args.workflow.isDefault && this.args.workflow.updatedAt;
  }
}
