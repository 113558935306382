export default {
  "card": "tD",
  "avatar": "tV",
  "content": "tL",
  "content-top": "tT",
  "fullname": "tz",
  "representative": "ta",
  "icon": "tH",
  "type": "tO",
  "menu-container": "tm",
  "dropdown-menu": "tp",
  "menu-item": "tx body-2",
  "delete": "tw"
};
