/* import __COLOCATED_TEMPLATE__ from './account-details-download.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SUPPORTED_LOCALES } from 'qonto/constants/locales';

export default class AccountDetailsDownloadComponent extends Component {
  @service segment;
  @service intl;

  get organizationLocale() {
    return this.args.bankAccount.organization.legalCountry.toLowerCase();
  }

  get sortedLocales() {
    return this._sortLocales(SUPPORTED_LOCALES, this.organizationLocale);
  }

  _sortLocales(allLocales, orgLocale) {
    return allLocales.sort((a, b) => {
      // orgLocale always comes first
      if (a === orgLocale) {
        return -1;
      }

      if (b === orgLocale) {
        return 1;
      }

      // english comes before all other locales
      if (a === 'en') {
        return -1;
      }

      if (b === 'en') {
        return 1;
      }

      // otherwise, sort alphabetically considering
      // there are no equal locales
      return a < b ? -1 : 1;
    });
  }

  @action
  trackLocaleDownload(locale) {
    if (locale === this.organizationLocale) {
      this.segment.track('account_details_download_local');
    } else {
      this.segment.track(`account_details_download_${locale}`);
    }
  }

  @action
  downloadLinkLabel(locale) {
    return this.intl.t(`bank_accounts.share.download.link-${locale}`);
  }
}
