export default {
  "card": "tc",
  "row": "tq",
  "icon": "tZ",
  "unrevokable": "tR",
  "active": "tQ",
  "kyc-pending": "te",
  "shareholder-info": "tB",
  "name": "tE",
  "iban": "td",
  "tooltip": "tI",
  "shareholder-capital": "tt",
  "shareholder-capital-amount": "tM",
  "deposit-capital": "tP",
  "valid": "tl",
  "pending": "tX",
  "promised-capital-valid": "tC",
  "promised-capital": "tk"
};
