/* import __COLOCATED_TEMPLATE__ from './new.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class BeneficiaryUploadNewComponent extends Component {
  @service router;

  abortRoute = 'transfers.new';
  confirmRoute = 'beneficiaries.multi.review';

  fileUploadTask = dropTask(async multiBeneficiary => {
    let mb = await multiBeneficiary.save();
    this.router.transitionTo(this.confirmRoute, mb);
  });

  @action
  handleGoBack() {
    this.router.transitionTo(this.abortRoute);
  }
}
