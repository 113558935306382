import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import styles from './ordered-steps.strict-module.css';
import StepSelectors from './step-selectors';
export default class ApprovalWorkflowFormOrderedSteps extends Component {
    get isRemoveButtonDisabled() {
        return this.args.numberOfOrders === 1;
    }
    get stepOrder() {
        return this.args.index + 1;
    }
    get steps() {
        let { ruleset: ruleset1 } = this.args;
        return ruleset1.steps.filter((step1)=>step1.order === this.stepOrder);
    }
    @action
    validateOperationSelector(steps1) {
        for (let step1 of steps1){
            if (!step1.operation) step1.errors.add('operation', VALIDATION_ERRORS.STEP_OPERATION_EMPTY);
            else if (step1.errors?.get('operation')) step1.errors.remove('operation');
        }
    }
    get operationErrors() {
        for (let step1 of this.steps){
            if (step1.errors?.get('operation')) return step1.errors?.get('operation');
        }
    }
    @action
    handleClose(steps1, ruleset1, stepOrder1) {
        let invalidUserErrors1 = [
            VALIDATION_ERRORS.MEMBERSHIP_NO_PERMISSION,
            VALIDATION_ERRORS.MEMBERSHIP_NOT_ACTIVE,
            VALIDATION_ERRORS.MEMBERSHIP_NOT_FOUND,
            VALIDATION_ERRORS.NO_ACTIVE_USER_WITH_ROLE,
            VALIDATION_ERRORS.INACTIVE_MEMBERSHIP,
            VALIDATION_ERRORS.NO_PERMISSION_TO_REVIEW,
            VALIDATION_ERRORS.NO_ACTIVE_USERS,
            VALIDATION_ERRORS.UNSUPPORTED_ROLE
        ];
        // Filter steps of this order that have invalid approvers
        let filteredSteps1 = ruleset1.steps.filter((step1)=>{
            let error1 = step1.errors?.get('value');
            if (error1 && step1.order === stepOrder1) {
                let isInvalid1 = invalidUserErrors1.includes(error1[0]?.message);
                return !isInvalid1;
            }
            return true;
        });
        // Handle approvers errors
        for (let step1 of steps1){
            if (!step1.value) step1.errors.add('value', VALIDATION_ERRORS.STEP_APPROVERS_EMPTY);
            else step1.errors.remove('value');
        }
        this.args.updateTriggerMap(); // We call it without arugments to avoid reopening
        if (ruleset1.steps.length !== filteredSteps1.length) ruleset1.steps = filteredSteps1;
    }
    get approversErrors() {
        for (let step1 of this.steps){
            if (step1.errors?.get('value')) return step1.errors?.get('value');
        }
    }
    get shouldApproversSelectorBeOpen() {
        return this.args.approversSelectorsTriggerMap[this.args.index]?.shouldOpen;
    }
    get lastApproverSelected() {
        return this.args.approversSelectorsTriggerMap[this.args.index]?.lastSelection;
    }
    static{
        template(`
    <div class={{styles.ordered-steps-container}} data-test-approval-workflow-form-ordered-steps>
      <p
        class='body-1 {{styles.rule-title}}'
        data-test-approval-workflow-form-ordered-step-label
      >{{t 'approval-workflows.form.steps.label'}}</p>
      <StepSelectors
        @onApproversChange={{@onApproversChange}}
        @approversOptions={{@steps.approverOptions}}
        @selectedOperation={{@selectedOperation}}
        @handleOperationTypeChange={{@handleOperationTypeChange}}
        @handleStepRemoval={{@handleStepRemoval}}
        @isRemoveButtonDisabled={{this.isRemoveButtonDisabled}}
        @validateOperation={{fn this.validateOperationSelector this.steps}}
        @operationErrors={{this.operationErrors}}
        @approversErrors={{this.approversErrors}}
        @handleApproversSelectorClose={{fn this.handleClose this.steps @ruleset this.stepOrder}}
        @shouldApproversSelectorBeOpen={{this.shouldApproversSelectorBeOpen}}
        @lastApproverSelected={{this.lastApproverSelected}}
        data-test-approval-workflow-form-ordered-step
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
