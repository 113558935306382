export default {
  "container": "Mg",
  "label": "MD",
  "amount-input": "MV",
  "error": "ML",
  "error-message": "MT",
  "title": "Mz",
  "subtitle": "Ma",
  "submit-button": "MH btn btn--primary btn--large"
};
