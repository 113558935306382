export default {
  "wrapper": "Qo",
  "core-information": "Qn",
  "iban": "Qi",
  "bic": "QA",
  "full-line": "QY",
  "secondary-information": "Qh",
  "title": "Qf",
  "rib": "QK",
  "rib-copy": "QG",
  "flex-column": "Qr",
  "label": "Qb body-1",
  "value": "Qy body-2",
  "right-spaced": "QU",
  "name": "Qj title-3",
  "disclaimer": "QW"
};
