/* import __COLOCATED_TEMPLATE__ from './quotes.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { importAsset } from 'qonto/helpers/import-asset';
import { ErrorInfo } from 'qonto/utils/error-info';

const UPSELL_IMAGES = {
  en: '/illustrations/quotes/quote-upsell-en.png',
  es: '/illustrations/quotes/quote-upsell-es.png',
  fr: '/illustrations/quotes/quote-upsell-fr.png',
  it: '/illustrations/quotes/quote-upsell-it.png',
  de: '/illustrations/quotes/quote-upsell-de.png',
};

export default class DiscoverUpsellReceivableInvoicesComponent extends Component {
  badgeHighlight = BadgeHighlight;

  @service localeManager;
  @service modals;
  @service subscriptionManager;
  @service segment;
  @service sentry;

  constructor() {
    super(...arguments);
    if (this.hasInitialTrial) {
      // eslint-disable-next-line ember-concurrency/no-perform-without-catch
      this.initRecommendationTask.perform();
    }
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('receivable_invoices');
  }

  get imagePath() {
    return importAsset([UPSELL_IMAGES[this.localeManager.locale]]);
  }

  get activeTrialRemainingDays() {
    return this.subscriptionManager.currentSubscription?.activeTrialRemainingDays;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasInitialTrialUpsell() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrialUpsell;
  }

  @action onTrialClick() {
    this.segment.track('quote_free-trial_clicked');
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  initRecommendationTask = dropTask(async () => {
    try {
      let { code } = await this.subscriptionManager.upgradeRecommendation('receivableInvoices');
      return code;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
