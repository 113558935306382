/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class CardSidebarMain extends Component {
  @service organizationManager;
  @service abilities;
  @service userManager;

  @reads('organizationManager.organization') currentOrganization;

  get areRestrictionsShown() {
    let { card } = this.args;

    if (card?.isAdvertising) return false;

    let canUpdateSettings = this.abilities.can('update settings card');
    let hasRestrictions = card?.activeDaysOption || card?.hasCategoryTags;

    let canViewAdvancedSettings = this.abilities.can('view restrictions card');

    return canViewAdvancedSettings && (canUpdateSettings || hasRestrictions);
  }

  get isCardHolder() {
    return this.args.card.holder.get('id') === this.organizationManager.membership.id;
  }

  get isScaEnabled() {
    return this.userManager.currentUser.scaEnabled;
  }

  get cardInitiatorId() {
    let { card } = this.args;
    return card.belongsTo('holder').id();
  }
}
