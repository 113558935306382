export default {
  "sub-menu": "Np",
  "collapsed": "Nx",
  "collapse-left": "Nw",
  "responsive": "NN",
  "expanded": "Nv",
  "no-slide": "No",
  "visible": "Nn",
  "hidden": "Ni",
  "group": "NA",
  "promo-box": "NY"
};
