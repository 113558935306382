export default {
  "row": "OJ",
  "cell": "Os body-2",
  "empty": "OF",
  "cell-content": "Og",
  "cell-content--amount": "OD body-1",
  "icon": "OV",
  "description": "OL",
  "tertiary": "OT",
  "details": "Oz"
};
