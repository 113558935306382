export default {
  "header": "aG",
  "header__main": "ar",
  "note": "ab",
  "review-status": "ay",
  "reviewer-wrapper": "aU",
  "reviewer": "aj",
  "decline-note": "aW",
  "decline-note-title": "au",
  "status-dot": "HS",
  "approved": "Hc",
  "rejected": "Hq"
};
