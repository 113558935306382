export default {
  "header": "qC",
  "header-type": "qk",
  "header-infos": "qJ",
  "title": "qs",
  "close": "qF",
  "close-icon": "qg",
  "subtitle": "qD",
  "date": "qV",
  "container": "qL",
  "picto": "qT",
  "icon": "qz",
  "general": "qa",
  "amount": "qH",
  "infobox": "qO"
};
