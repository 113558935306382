export default {
  "header-cell": "mX",
  "empty": "mC",
  "right": "mk",
  "debitor": "mJ",
  "frequency": "ms",
  "date": "mF",
  "status": "mg",
  "amount": "mD",
  "header-content": "mV caption-bold",
  "active": "mL",
  "row--sidebar-open": "mT"
};
