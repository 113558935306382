export default {
  "budget-creation-form": "Ei",
  "period-label": "EA",
  "periods": "EY",
  "yearly": "Eh",
  "yearly-toggle": "Ef",
  "toggle-tooltip": "EK",
  "tooltip": "EG",
  "year": "Er",
  "total-budget": "Eb",
  "total-budget-value": "Ey",
  "divider": "EU"
};
