/* import __COLOCATED_TEMPLATE__ from './tile-layout.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeStatus } from 'design-system-kit';

import { ACCOUNT_STATUS, ACCOUNT_TYPE } from 'qonto/constants/bank-account';

export default class BankAccountTileLayoutComponent extends Component {
  badgeStatus = BadgeStatus;

  @service keyboardFocus;

  @tracked focused = false;

  get isRemuneratedWaiting() {
    return (
      this.args.account.accountType === ACCOUNT_TYPE.REMUNERATED &&
      this.args.account.status === ACCOUNT_STATUS.WAITING
    );
  }

  @action
  setFocused(focused) {
    this.focused = focused;
  }
}
