/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { equal } from 'macro-decorators';

import { CARD_LEVELS, CARD_STATUSES } from 'qonto/constants/cards';
import { KYC_STATUS } from 'qonto/constants/membership';
import tinyMaskPan from 'qonto/utils/tiny-mask-pan';

export default class CardSidebarDisclaimer extends Component {
  @service abilities;
  @service intl;
  @service organizationManager;

  @equal('organizationManager.organization.kybStatus', 'pending') isKYBPending;
  @equal('organizationManager.membership.kycSubmitted', false) isKycNotSubmitted;

  get ghostCardDisclaimer() {
    let { card, isCardHolder } = this.args;

    switch (card.status) {
      case CARD_STATUSES.DELETED: {
        if (card.upsold) {
          return { text: this.intl.t('cards.sidebar.disclaimer.deleted-upsold'), level: 'info' };
        }
        return isCardHolder
          ? { text: this.intl.t('cards.sidebar.disclaimer.deleted'), level: 'info' }
          : { text: this.intl.t('cards.sidebar.disclaimer.deleted-admin'), level: 'info' };
      }
      case CARD_STATUSES.ORDER_CANCELED:
        return isCardHolder
          ? { text: this.intl.t('cards.sidebar.disclaimer.deleted'), level: 'info' }
          : { text: this.intl.t('cards.sidebar.disclaimer.deleted-admin'), level: 'info' };
      case CARD_STATUSES.EXPIRED:
      case CARD_STATUSES.PRE_EXPIRED:
        return isCardHolder
          ? { text: this.intl.t('cards.sidebar.disclaimer.expired'), level: 'info' }
          : { text: this.intl.t('cards.sidebar.disclaimer.expired-admin'), level: 'info' };
      case CARD_STATUSES.LOST:
        return isCardHolder
          ? { text: this.intl.t('cards.sidebar.disclaimer.lost'), level: 'info' }
          : { text: this.intl.t('cards.sidebar.disclaimer.lost-admin'), level: 'info' };
      case CARD_STATUSES.STOLEN:
        return isCardHolder
          ? { text: this.intl.t('cards.sidebar.disclaimer.stolen'), level: 'info' }
          : { text: this.intl.t('cards.sidebar.disclaimer.stolen-admin'), level: 'info' };
      default:
        return null;
    }
  }

  get kybPendingDisclaimer() {
    switch (this.args.card.cardLevel) {
      case CARD_LEVELS.FLASH:
        return { text: this.intl.t('cards.sidebar.disclaimer.kyb-pending.flash'), level: 'info' };
      case CARD_LEVELS.VIRTUAL:
        return { text: this.intl.t('cards.sidebar.disclaimer.kyb-pending.virtual'), level: 'info' };
      case CARD_LEVELS.ADVERTISING:
        return {
          text: this.intl.t('cards.sidebar.disclaimer.kyb-pending.advertising'),
          level: 'info',
        };
      default:
        return {
          text: this.intl.t('cards.sidebar.disclaimer.kyb-pending.physical'),
          level: 'info',
        };
    }
  }

  kycPendingDisclaimer(card) {
    if (card.isFlash) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.kyc-unsubmitted.instant'),
        level: 'info',
      };
    } else if (card.isAdvertising) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.kyc-unsubmitted.advertising'),
        level: 'info',
      };
    } else if (card.isVirtual) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.kyc-regulatory-checks.fourthline.virtual'),
        level: 'info',
      };
    }

    return {
      text: this.intl.t('cards.sidebar.disclaimer.kyc-unsubmitted.physical'),
      level: 'info',
    };
  }

  get disclaimer() {
    let { card, isCardHolder } = this.args;

    if (card.ghost) {
      return this.ghostCardDisclaimer;
    }

    if (this.isKycNotSubmitted) {
      if (isCardHolder) {
        return this.kycPendingDisclaimer(card);
      }

      if (this.abilities.can('set PIN for card', card)) {
        let text = this.intl.t('cards.sidebar.disclaimer.set-pin.fourthline.kyc');
        return {
          text,
          level: 'info',
        };
      }
    }

    if (this.isKYBPending) {
      return this.kybPendingDisclaimer;
    }

    if (!card.hadPinOperation && card.pinBlocked && isCardHolder && card.isPhysical) {
      return this._enableCardPermanentlyPinBlockedDisclaimer();
    }

    if (card.isUpsold) {
      let deletionDate = dateToken({
        date: card.discardOn,
        locale: this.intl.locale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      });

      return {
        text: this.intl.t('cards.sidebar.disclaimer.upsold', { date: deletionDate }),
        level: 'info',
      };
    }

    if (card.isRenewable) {
      return this._renewableCardDisclaimer(card, isCardHolder);
    }

    if (card.isRenewed) {
      return this._renewedCardDisclaimer(card, isCardHolder);
    }

    if (this.abilities.can('set PIN for card', card)) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.set-pin.holder'),
        level: 'warning',
      };
    } else if (card.isPinSettable) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.set-pin.non-holder', {
          holder: card.get('holder.fullName'),
        }),
        level: 'warning',
      };
    }

    if (card.holder.get('kycStatus') !== KYC_STATUS.ACCEPTED) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.kyc-pending'),
        level: 'info',
      };
    }

    if (card.pending) {
      return this._pendingCardDisclaimer(card, isCardHolder);
    }

    if (card.isToPinActivate) {
      return this._toPinActivateDisclaimer(card, isCardHolder);
    }

    if (card.isReplacement) {
      return this._replacementCardDisclaimer(card, isCardHolder);
    }

    return null;
  }

  _toPinActivateDisclaimer(card, isCardHolder) {
    if (card.renewal) {
      return {
        text: this.intl.t('cards.sidebar.disclaimer.renewal.to-pin-activate'),
        level: 'info',
      };
    } else if (isCardHolder) {
      return {
        text: this.abilities.cannot('see x pay card')
          ? this.intl.t('cards.sidebar.disclaimer.no-xpay')
          : this.intl.t('cards.sidebar.disclaimer.to-pin-activate'),
        level: 'info',
      };
    }
    return null;
  }

  _enableCardPermanentlyPinBlockedDisclaimer() {
    return {
      text: this.intl.t('cards.sidebar.disclaimer.pin-blocked-permanently'),
      level: 'warning',
    };
  }

  _pendingCardDisclaimer(card, isCardHolder) {
    if (card.renewal) {
      let text;
      let lastDigits = tinyMaskPan(card.parentCardSummary?.last4);
      text = isCardHolder
        ? this.intl.t('cards.sidebar.disclaimer.renewal.pending.holder', { lastDigits })
        : this.intl.t('cards.sidebar.disclaimer.renewal.pending.non-holder', {
            lastDigits,
            cardHolderName: card.get('holder.fullName'),
          });
      return { text, level: 'info' };
    }

    switch (card.cardLevel) {
      case CARD_LEVELS.FLASH: {
        let text = this.intl.t('cards.sidebar.disclaimer.kyc-regulatory-checks.flash');
        return { text, level: 'info' };
      }
      case CARD_LEVELS.VIRTUAL:
        return { text: this.intl.t('cards.sidebar.disclaimer.ready-soon.virtual'), level: 'info' };
      case CARD_LEVELS.ADVERTISING:
        return {
          text: this.intl.t('cards.sidebar.disclaimer.ready-soon.advertising'),
          level: 'info',
        };
      default:
        return { text: this.intl.t('cards.sidebar.disclaimer.ready-soon.physical'), level: 'info' };
    }
  }

  _renewableCardDisclaimer(card, isCardHolder) {
    return isCardHolder
      ? {
          text: this.intl.t('cards.sidebar.disclaimer.expiring-soon.not-confirmed.holder'),
          level: 'warning',
          link: {
            flow: 'card-renew-physical',
            stepId: 'verify-delivery-address',
            text: this.intl.t('cards.sidebar.disclaimer.expiring-soon.renewal-link'),
            trackingEvent: 'cards_side_panel_verify_address',
          },
        }
      : {
          text: this.intl.t('cards.sidebar.disclaimer.expiring-soon.not-confirmed.non-holder', {
            cardHolderName: card.get('holder.fullName'),
          }),
          level: 'info',
        };
  }

  _renewedCardDisclaimer(card, isCardHolder) {
    let text;
    if (card.isPhysical) {
      text = isCardHolder
        ? this.intl.t('cards.sidebar.disclaimer.expiring-soon.confirmed.holder')
        : this.intl.t('cards.sidebar.disclaimer.expiring-soon.confirmed.non-holder', {
            cardHolderName: card.get('holder.fullName'),
          });
    } else {
      let expiryDate = dateToken({
        date: card.expDate,
        locale: this.intl.locale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      });
      text = isCardHolder
        ? this.intl.t('cards.sidebar.disclaimer.expiring-soon.virtual.holder', { expiryDate })
        : this.intl.t('cards.sidebar.disclaimer.expiring-soon.virtual.non-holder', {
            cardHolderName: card.get('holder.fullName'),
            expiryDate,
          });
    }
    return { text, level: 'info' };
  }

  _replacementCardDisclaimer(card, isCardHolder) {
    let lastDigits = tinyMaskPan(card.parentCardSummary?.last4);
    let text = isCardHolder
      ? this.intl.t('cards.sidebar.disclaimer.renewal.virtual.holder', { lastDigits })
      : this.intl.t('cards.sidebar.disclaimer.renewal.virtual.non-holder', {
          lastDigits,
          cardHolderName: card.get('holder.fullName'),
        });
    return { text, level: 'info' };
  }
}
