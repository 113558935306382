/* import __COLOCATED_TEMPLATE__ from './attachment-preview.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { match } from 'macro-decorators';

export default class AttachmentPreviewComponent extends Component {
  spinner = Spinner;

  @match('args.attachmentType', /^image/) isImagePreview;
  @match('args.attachmentType', /pdf/) isPdfPreview;

  @tracked isIframeLoaded;
}
