export default {
  "card": "MS",
  "avatar": "Mc",
  "owner": "Mq",
  "content": "MZ",
  "identity": "MR",
  "fullname": "MQ",
  "type": "Me",
  "menu-container": "MB",
  "dropdown-menu": "ME",
  "menu-item": "Md body-2",
  "delete": "MI"
};
