import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import DisclaimerBlock from '@qonto/ui-kit/components/disclaimer/block';
import DisclaimerInline from '@qonto/ui-kit/components/disclaimer/inline';
import SelectorAdd from '@qonto/ui-kit/components/selector/add';
import TranslationWithArgs from '@qonto/ui-kit/components/translation-with-args';
import { dropTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import preventDefault from 'ember-event-helpers/helpers/prevent-default';
import { t } from 'ember-intl';
import { VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import styles from './form.strict-module.css';
import ApprovalWorkflowFormRuleset from './form/ruleset';
const MAX_RULESETS = 10;
export default class ApprovalWorkflowForm extends Component {
    @service
    intl;
    @service
    store;
    saveWorkflowTask = dropTask(async ()=>{
        let { rulesets: rulesets1 } = this.args.workflow;
        let hasErrors1 = rulesets1?.some((_1, rulesetIndex1)=>{
            if (rulesetIndex1 === 0) return false;
            this.validateConditionAmount(rulesetIndex1);
            return rulesets1[rulesetIndex1].conditions.some((condition1)=>condition1.errors.length > 0);
        });
        if (hasErrors1) return;
        this.#organizeSteps();
        await this.args.workflow.save();
        this.args.onSave();
    });
    #organizeSteps() {
        this.args.workflow.rulesets.forEach((ruleset1)=>{
            // BE expects steps to be ordered by 'order'
            let steps1 = [
                ...ruleset1.steps
            ].sort((a1, b1)=>a1.order - b1.order);
            // Make sure that there are no gaps in order numbers
            steps1 = steps1.map((step1, index1, steps1)=>{
                if (index1 === 0 && step1.order !== 1) step1.order = 1;
                if (index1 > 0 && step1.order > steps1[index1 - 1].order + 1) {
                    step1.order = steps1[index1 - 1].order + 1;
                }
                return step1;
            });
            ruleset1.steps = steps1;
        });
    }
    #checkConditionsAmounts(rulesets1, rulesetIndex1, conditionToValidate1) {
        conditionToValidate1.errors.remove('value');
        let amountToValidate1 = parseFloat(conditionToValidate1.value.value, 10);
        for (let [index1, ruleset1] of rulesets1.entries()){
            let amountToValidateAgainst1 = parseFloat(ruleset1.conditions.at(0).value.value, 10);
            if (isNaN(amountToValidate1)) {
                conditionToValidate1.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_EMPTY);
                return false;
            }
            if (index1 < rulesetIndex1 && amountToValidate1 < amountToValidateAgainst1) {
                conditionToValidate1.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_LOWER);
                return false;
            }
            if (index1 !== rulesetIndex1 && amountToValidate1 === amountToValidateAgainst1) {
                conditionToValidate1.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_SAME);
                return false;
            }
            if (index1 > rulesetIndex1 && amountToValidate1 > amountToValidateAgainst1) {
                conditionToValidate1.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_HIGHER);
                return false;
            }
        }
        return true;
    }
    @action
    validateConditionAmount(rulesetIndex1) {
        let { rulesets: rulesets1 } = this.args.workflow;
        let conditionToValidate1 = rulesets1[rulesetIndex1].conditions.at(0);
        let isValid1 = this.#checkConditionsAmounts(rulesets1, rulesetIndex1, conditionToValidate1);
        if (isValid1) {
            let hasRulesetsAnyConditionsErrors1 = rulesets1.some((ruleset1)=>ruleset1.conditions.at(0).errors.length > 0);
            if (hasRulesetsAnyConditionsErrors1) {
                for (let [index1, ruleset1] of rulesets1.entries()){
                    let errors1 = ruleset1.conditions.at(0).errors.get('value');
                    if (errors1) this.#checkConditionsAmounts(rulesets1, index1, ruleset1.conditions.at(0));
                }
            }
        }
    }
    @action
    removeRuleset(workflow1, removalIndex1) {
        if (workflow1.rulesets?.length === 1) return;
        workflow1.rulesets = workflow1.rulesets.filter((ruleset1, index1)=>index1 !== removalIndex1);
    }
    get cannotAddNewRuleset() {
        let { workflow: workflow1 } = this.args;
        return workflow1?.rulesets?.length === MAX_RULESETS;
    }
    @action
    addNewRuleset() {
        if (this.cannotAddNewRuleset) {
            return;
        }
        let { workflow: workflow1 } = this.args;
        let condition1 = this.store.createRecord('approval-workflow/condition', {
            field: 'amount',
            operation: 'gte',
            value: {
                value: '0.00',
                currency: 'EUR'
            }
        });
        let step1 = this.store.createRecord('approval-workflow/step');
        step1.set('order', 1);
        let ruleset1 = this.store.createRecord('approval-workflow/ruleset', {
            conditions: [
                condition1
            ],
            steps: [
                step1
            ]
        });
        workflow1.rulesets = [
            ...workflow1.rulesets,
            ruleset1
        ];
    }
    static{
        template(`
    <div ...attributes>
      <h1 class='mb-24 title-1' data-test-approval-workflow-form-title>{{@title}}</h1>
      <form
        class='body-1'
        {{on 'submit' (preventDefault (perform this.saveWorkflowTask))}}
        data-test-approval-workflow-form
      >
        {{#each @workflow.rulesets as |ruleset index|}}
          <ApprovalWorkflowFormRuleset
            @approverOptions={{@approverOptions}}
            @index={{index}}
            @ruleset={{ruleset}}
            @rulesets={{@workflow.rulesets}}
            @validate={{fn this.validateConditionAmount index}}
            @removeRuleset={{fn this.removeRuleset @workflow index}}
            class='mb-16'
          />
        {{/each}}
        <SelectorAdd
          @title={{t 'approval-workflows.form.btn.add-rule'}}
          class='mb-16 {{styles.add-rule-button}}'
          @onClick={{this.addNewRuleset}}
          @disabled={{this.cannotAddNewRuleset}}
          data-test-approval-workflow-form-add-rule-button
        />
        {{#if @shouldDisplayInviteMembersDisclaimer}}
          <DisclaimerBlock
            @level='warning'
            class='mb-16 {{styles.invite-expense-users-disclaimer}}'
            data-test-approval-workflow-form-invite-members-disclaimer
          >
            <TranslationWithArgs
              @key='approval-workflows.form.disclaimers.invite-body'
              @text={{t
                'approval-workflows.form.disclaimers.invite-body'
                link=(t 'approval-workflows.form.disclaimers.invite-link')
              }}
              as |varText|
            >
              <a
                href='#'
                {{on 'click' @redirectToUserAccess}}
                data-test-approval-workflow-invite-member-link
              >
                {{varText}}
              </a>
            </TranslationWithArgs>
          </DisclaimerBlock>
        {{/if}}
        <DisclaimerInline @level='info' class='mb-32' data-test-approval-workflow-form-disclaimer>
          {{t 'approval-workflows.form.disclaimers.new-requests'}}
        </DisclaimerInline>
        <button
          aria-disabled={{this.submitButtonIsDisabled}}
          disabled={{this.submitButtonIsDisabled}}
          type='submit'
          class='btn btn--primary'
          data-test-approval-workflow-form-submit-button
        >{{t 'approval-workflows.form.btn.save'}}</button>
      </form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
