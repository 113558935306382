/* import __COLOCATED_TEMPLATE__ from './period-limit.hbs'; */
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class CardSettingsPeriodLimitComponent extends Component {
  @inject intl;

  get displayValue() {
    return dayjs(this.args.value).format(DATE_PICKER_FIELD_FORMAT);
  }

  get formattedValue() {
    let dateValue = dayjs(this.args.value);
    if (dateValue.isValid()) {
      return dateToken({
        date: dateValue,
        locale: this.intl.locale,
      });
    }
  }

  @action
  onChange(value) {
    this.args.onChange(value);
  }
}
