export default {
  "row": "Ly body-1",
  "active": "LU",
  "cell": "Lj",
  "empty": "LW",
  "connection": "Lu",
  "connection-avatar": "TS",
  "connection-name": "Tc",
  "member-name": "Tq body-2",
  "member-role": "TZ caption",
  "status": "TR",
  "failure": "TQ",
  "error": "Te",
  "hidden": "TB"
};
