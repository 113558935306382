/* import __COLOCATED_TEMPLATE__ from './application-sidebar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CONNECT_FEATURE_LEVELS, INTEGRATION_TYPES } from 'qonto/constants/connect';

export default class QontoHubApplicationSidebar extends Component {
  @service abilities;
  @service intl;
  @service subscriptionManager;
  @service organizationManager;

  get isPartnership() {
    return this.args.application.integration_type === INTEGRATION_TYPES.partnerships;
  }

  get isInternal() {
    return this.args.application.integration_type === INTEGRATION_TYPES.internal;
  }

  get resourceLinks() {
    return this.args.application.useful_links?.filter(({ link }) => Boolean(link.url)) || [];
  }

  get supportLinks() {
    return this.args.application.support_links?.filter(({ link }) => Boolean(link.url)) || [];
  }

  get isIntegrationAvailable() {
    switch (this.args.application.minimum_integration_level) {
      case CONNECT_FEATURE_LEVELS.silver:
        return this.abilities.can('access silver connect');
      case CONNECT_FEATURE_LEVELS.gold:
        return this.abilities.can('access gold connect');
      default:
        return true;
    }
  }

  get isTrialAvailable() {
    return Boolean(
      this.subscriptionManager.currentSubscription.findTrial('connect_integration_level_silver')
    );
  }

  get integrationPricing() {
    if (this.isIntegrationAvailable) {
      return this.intl.t('qonto-hub.connect.details.pricing.available');
    } else if (this.isTrialAvailable) {
      return this.intl.t('qonto-hub.connect.details.pricing.trial');
    } else {
      return this.intl.t('qonto-hub.connect.details.pricing.unavailable');
    }
  }
}
